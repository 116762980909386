import React from 'react';
import './ContractMarket.css'
import MUIDataTable from 'mui-datatables';
import { createTheme, ThemeProvider } from '@mui/material';
import Man1 from '../../images/man1.jpg';
import Man2 from '../../images/man2.jpg';

const index = () => {

  const columns = [
    {
      name: "SellerPic",
      label: "Seller",
      options: {
        filter: false,
        sort: true,
        customBodyRender: ({value, alt}) => (
          <img src={value} alt={alt} className='seller-pic' />
        )
      }
    },
    {
      name: "SellerName",
      label: "Seller Name",
      options: {
        filter: false,
        sort: true,
        customBodyRender: ({value, link}) => (
          <a href={link} target='_blank' className='sn-pd-text'>{value}</a>
        )
      }
    },
    {
      name: "PrimaryDeal",
      label: "Primary Deal",
      options: {
          filter: false,
          sort: true,
          customBodyRender: ({link, title}) => (
            <a href={link} target='_blank' className='sn-pd-text'>{title}</a>
          )
      }
    },
    {
      name: "UnderlyingValue",
      label: "Underlying Value",
      options: {
          filter: false,
          sort: true,
        }
    },
    {
      name: "Category",
      label: "Category",
      options: {
        filter: true,
        sort: true,
        customBodyRender: ({link, value}) => (
          <a href={link} target='_blank' className='tag-text'>{value}</a>
        )
      }
    },
    {
      name: "Type",
      label: "Type",
      options: {
        filter: true,
        sort: true,
        customBodyRender: ({link, value}) => (
          <a href={link} target='_blank' className='tag-text'>{value}</a>
        )
      }
    },
    {
      name: "Ask",
      label: "Seller's Ask",
      options: {
        filter: false,
        sort: true,
        customBodyRender: ({value}) => (
          <div className='parent-ask-hbid'>
            <p className='ask-text-hbid'>{value}</p>
            <button className='take-btn-hbid'>Take</button>
          </div>
        )
      }
    },
    {
      name: "HBid",
      label: "Highest Bid",
      options: {
        filter: false,
        customBodyRender: ({value}) => (
          <div className='parent-ask-hbid'>
            <p className='ask-text-hbid'>{value}</p>
            <button className='take-btn-hbid'>Out Bid</button>
          </div>
        )
      }
    },
  ];

  // Check for customSort
  // https://github.com/gregnb/mui-datatables/blob/master/examples/customize-sorting/index.js

  const data = [
      // [{value: Man1, alt: "U Hla Myo Than Swe"}, {link: "#", value: "U Hla Myo Than Swe"}, {link: "#", 
      // title: "300,000 Tons of Corn"}, "$50,000,000", {link: "#", value: "Commodity (Ed)"}, 
      // {link: "#",value: "NYCA"}, {value: "$55,000,000"}, {value:"$52,500,000"}],

      // [{value: Man2, alt: "U Mya Than Thuyein Win"}, {link: "#", value: "U Mya Than Thuyein Win"}, {link: "#", 
      // title: "300,000 Tons of Corn"}, "$50,000,000", {link: "#", value: "Commodity (Ed)"}, 
      // {link: "#",value: "NYCA"}, {value: "$55,000,000"}, {value: "$20,500,000"}],
  ];

  const options = {
    filterType: 'checkbox',
    filter: false,
    elevation: 0,
    rowsPerPage: 7,
    rowsPerPageOptions: [7],
    search: false,
    selectableRows: false,
    print: false,
    download: false,
  };

  const getMuiTheme = () => createTheme({
    palette: {
      background: {
        paper: "#212121",
        default: "#212121",
      },
      mode: 'dark'
    },
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            border: 'none', // Remove all borders
            textAlign: 'center',
            fontSize: '12px',
            opacity: '0.05'
          },
        },
      },
    },
  });

  return (
    <div className='parent-secondary-market'>
      <ThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          data={data}
          columns={columns}
          options={options}
        />
      </ThemeProvider>

      <div style={{ width: "100%" }} align="center">
        <h2 style={{ fontWeight: '100' }}>This section is not yet available on <b>ManyMarkup</b> MVP version</h2>
      </div>
    </div>
  )
}

export default index