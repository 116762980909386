import React from 'react';
import './hero2.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { faCircle as faCircle2 } from '@fortawesome/free-solid-svg-icons';

const Hero2 = () => {
  return (
    <div className='hero2-parent'>
        <div className="group">
        <h2 className='title'>Customize Your Opportunity</h2>
        <h5 className='description'>Easily search your investable opportunities as you filter</h5>
            <div className='title_div' align="center">
                <h5>Types of Investment</h5>
            </div>
            <div className="list">
                <input type="checkbox" id='value_1' className='checkbox' />
                <label for="value_1" className='item'>
                    <div className="check">
                        <FontAwesomeIcon className='fai' icon={faCircle2} style={{color: "grey",}} />
                        <FontAwesomeIcon className='fai' icon={faCircle2} style={{color: "#00ff00",}} />
                    </div>
                    <h2 className='title2'>HYRA</h2>
                    <p className='subtitle'>High Yield Return Assets</p>
                </label>

                <input type="checkbox" id='value_2' className='checkbox' />
                <label for="value_2" className='item'>
                    <div className="check">
                        <FontAwesomeIcon className='fai' icon={faCircle2} style={{color: "grey",}} />
                        <FontAwesomeIcon className='fai' icon={faCircle2} style={{color: "#00ff00",}} />
                    </div>
                    <h2 className='title2'>SYA</h2>
                    <p className='subtitle'>Safe Yield Assets</p>
                </label>

                <input type="checkbox" id='value_3' className='checkbox' />
                <label for="value_3" className='item'>
                    <div className="check">
                        <FontAwesomeIcon className='fai' icon={faCircle2} style={{color: "grey",}} />
                        <FontAwesomeIcon className='fai' icon={faCircle2} style={{color: "#00ff00",}} />
                    </div>
                    <h2 className='title2'>NYCA</h2>
                    <p className='subtitle'>No Yield Claimable Assets</p>
                </label>
                
                <input type="checkbox" id='value_4' className='checkbox' />
                <label for="value_4" className='item'>
                    <div className="check">
                    <FontAwesomeIcon className='fai' icon={faCircle2} style={{color: "grey",}} />
                        <FontAwesomeIcon className='fai' icon={faCircle2} style={{color: "#00ff00",}} />
                    </div>
                    <h2 className='title2'>TPHA</h2>
                    <p className='subtitle'>Time Passive Holding Assets</p>
                </label>
            </div>

            <div className='title_div title_div2' align="center">
                <h5>Investment Categories</h5>
            </div>

            <div className="list">
                <input type="checkbox" id='value_5' className='checkbox' />
                <label for="value_5" className='item item2'>
                    <div className="check">
                        <FontAwesomeIcon className='fai' icon={faCircle2} style={{color: "grey",}} />
                        <FontAwesomeIcon className='fai' icon={faCircle2} style={{color: "#00ff00",}} />
                    </div>
                    <h2 className='title2'>Equity Market</h2>
                    <p className='subtitle'></p>
                </label>

                <input type="checkbox" id='value_6' className='checkbox' />
                <label for="value_6" className='item item2'>
                    <div className="check">
                        <FontAwesomeIcon className='fai' icon={faCircle2} style={{color: "grey",}} />
                        <FontAwesomeIcon className='fai' icon={faCircle2} style={{color: "#00ff00",}} />
                    </div>
                    <h2 className='title2'>Bond & Debt Market</h2>
                    <p className='subtitle'></p>
                </label>

                <input type="checkbox" id='value_7' className='checkbox' />
                <label for="value_7" className='item item2'>
                    <div className="check">
                        <FontAwesomeIcon className='fai' icon={faCircle2} style={{color: "grey",}} />
                        <FontAwesomeIcon className='fai' icon={faCircle2} style={{color: "#00ff00",}} />
                    </div>
                    <h2 className='title2'>Artwork Market</h2>
                    <p className='subtitle'></p>
                </label>
                
                <input type="checkbox" id='value_8' className='checkbox' />
                <label for="value_8" className='item item2'>
                    <div className="check">
                        <FontAwesomeIcon className='fai' icon={faCircle2} style={{color: "grey",}} />
                        <FontAwesomeIcon className='fai' icon={faCircle2} style={{color: "#00ff00",}} />
                    </div>
                    <h2 className='title2'>Personal Collection Market</h2>
                    <p className='subtitle'></p>
                </label>
                
                <input type="checkbox" id='value_9' className='checkbox' />
                <label for="value_9" className='item item2'>
                    <div className="check">
                        <FontAwesomeIcon className='fai' icon={faCircle2} style={{color: "grey",}} />
                        <FontAwesomeIcon className='fai' icon={faCircle2} style={{color: "#00ff00",}} />
                    </div>
                    <h2 className='title2'>Vehicle Market</h2>
                    <p className='subtitle'></p>
                </label>

                <input type="checkbox" id='value_10' className='checkbox' />
                <label for="value_10" className='item item2'>
                    <div className="check">
                        <FontAwesomeIcon className='fai' icon={faCircle2} style={{color: "grey",}} />
                        <FontAwesomeIcon className='fai' icon={faCircle2} style={{color: "#00ff00",}} />
                    </div>
                    <h2 className='title2'>Mining Products Market</h2>
                    <p className='subtitle'></p>
                </label>

                <input type="checkbox" id='value_11' className='checkbox' />
                <label for="value_11" className='item item2'>
                    <div className="check">
                        <FontAwesomeIcon className='fai' icon={faCircle2} style={{color: "grey",}} />
                        <FontAwesomeIcon className='fai' icon={faCircle2} style={{color: "#00ff00",}} />
                    </div>
                    <h2 className='title2'>Personal Jewellery Market</h2>
                    <p className='subtitle'></p>
                </label>

                <input type="checkbox" id='value_12' className='checkbox' />
                <label for="value_12" className='item item2 item_long_text'>
                    <div className="check">
                        <FontAwesomeIcon className='fai' icon={faCircle2} style={{color: "grey",}} />
                        <FontAwesomeIcon className='fai' icon={faCircle2} style={{color: "#00ff00",}} />
                    </div>
                    <h2 className='title2'>Commodity Market<br />(Edible)</h2>
                    <p className='subtitle'></p>
                </label>

                <input type="checkbox" id='value_13' className='checkbox' />
                <label for="value_13" className='item item2 item_long_text'>
                    <div className="check">
                        <FontAwesomeIcon className='fai' icon={faCircle2} style={{color: "grey",}} />
                        <FontAwesomeIcon className='fai' icon={faCircle2} style={{color: "#00ff00",}} />
                    </div>
                    <h2 className='title2'>Commodity Market<br />(Non-Edible)</h2>
                    <p className='subtitle'></p>
                </label>

                <input type="checkbox" id='value_14' className='checkbox' />
                <label for="value_14" className='item item2'>
                    <div className="check">
                        <FontAwesomeIcon className='fai' icon={faCircle2} style={{color: "grey",}} />
                        <FontAwesomeIcon className='fai' icon={faCircle2} style={{color: "#00ff00",}} />
                    </div>
                    <h2 className='title2'>Real Estate Market</h2>
                    <p className='subtitle'></p>
                </label>

                <input type="checkbox" id='value_15' className='checkbox' />
                <label for="value_15" className='item item2'>
                    <div className="check">
                        <FontAwesomeIcon className='fai' icon={faCircle2} style={{color: "grey",}} />
                        <FontAwesomeIcon className='fai' icon={faCircle2} style={{color: "#00ff00",}} />
                    </div>
                    <h2 className='title2'>Project & Fund Market</h2>
                    <p className='subtitle'></p>
                </label>

                
                <input type="checkbox" id='value_16' className='checkbox' />
                <label for="value_16" className='item item2'>
                    <div className="check">
                        <FontAwesomeIcon className='fai' icon={faCircle2} style={{color: "grey",}} />
                        <FontAwesomeIcon className='fai' icon={faCircle2} style={{color: "#00ff00",}} />
                    </div>
                    <h2 className='title2'>ESG Funds</h2>
                    <p className='subtitle'></p>
                </label>

                <input type="checkbox" id='value_17' className='checkbox' />
                <label for="value_17" className='item item2'>
                    <div className="check">
                        <FontAwesomeIcon className='fai' icon={faCircle2} style={{color: "grey",}} />
                        <FontAwesomeIcon className='fai' icon={faCircle2} style={{color: "#00ff00",}} />
                    </div>
                    <h2 className='title2'>Energy</h2>
                    <p className='subtitle'></p>
                </label>

                <input type="checkbox" id='value_18' className='checkbox' />
                <label for="value_18" className='item item2'>
                    <div className="check">
                        <FontAwesomeIcon className='fai' icon={faCircle2} style={{color: "grey",}} />
                        <FontAwesomeIcon className='fai' icon={faCircle2} style={{color: "#00ff00",}} />
                    </div>
                    <h2 className='title2'>Institutions</h2>
                    <p className='subtitle'></p>
                </label>

                <input type="checkbox" id='value_19' className='checkbox' />
                <label for="value_19" className='item item2'>
                    <div className="check">
                        <FontAwesomeIcon className='fai' icon={faCircle2} style={{color: "grey",}} />
                        <FontAwesomeIcon className='fai' icon={faCircle2} style={{color: "#00ff00",}} />
                    </div>
                    <h2 className='title2'>Sports & Clubs</h2>
                    <p className='subtitle'></p>
                </label>

                <input type="checkbox" id='value_20' className='checkbox' />
                <label for="value_20" className='item item2'>
                    <div className="check">
                        <FontAwesomeIcon className='fai' icon={faCircle2} style={{color: "grey",}} />
                        <FontAwesomeIcon className='fai' icon={faCircle2} style={{color: "#00ff00",}} />
                    </div>
                    <h2 className='title2'>Trade Finance</h2>
                    <p className='subtitle'></p>
                </label>
            </div>
        </div>
    </div>
  )
}

export default Hero2