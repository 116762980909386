import './App.css';
import Footer from './components/Footer';
import LoginRegister from './components/LoginRegister';
import Markets from './components/markets';
import TopNavbar from './components/navbar';
import RecentlyAddedProjects from './components/RecentlyAddedProjects';
import TTF from './components/ttf (Trendings, Top Projects, Featured Projects)';
import Wrapper from './WrapperForAppJS';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import TokenizeForm from './components/TokenizeForm';
import ProjectDetails from './components/ProjectDetails';
import UserDashboard from './components/UserDashboard';
import Hero from './components/hero';
import Hero2 from './components/hero/hero2';

function App() {
  return (
    <div className="App">
      
      <Router>
        <Routes>  
          <Route path="/" element={
            <div>
              <TopNavbar />
              {/* <Hero /> */}
              <Hero2 />
              <Wrapper>
                <TTF />
                <RecentlyAddedProjects />
                <Markets />
                {/* <h2 className="liquid-af">Liquid AF &#10549;</h2> */}
              </Wrapper>
              <Footer />
            </div>
          } />

          <Route path="/auth" element={
            <div>
              <TopNavbar />
                <LoginRegister />
              {/* <Footer /> */}
            </div>
          } />

          <Route path="/tokenize" element={
            <div>
              <TopNavbar />
              <Wrapper>
                <TokenizeForm />
              </Wrapper>
            </div>
          } />

          <Route path="/projectDetails" element={
            <div>
              <TopNavbar />
              <Wrapper>
                  <ProjectDetails />
              </Wrapper>
            </div>
          }/>

          <Route path="/user-dashboard" element={
            <div>
              <TopNavbar />
              <Wrapper>
                  <UserDashboard />
              </Wrapper>
            </div>
          }/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
