import React, { useState } from 'react';
import Logo from '../../images/logo4.png';
import './nav.css';
import { Link, Navigate } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const TopNavbar = () => {
    
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const navigate = useNavigate();

    const userAuthenticated = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/isUserAuth`, {
            headers: {
                "x-access-token": localStorage.getItem("token"),
            }
        }).then((res) => {
            // console.log(res);
            try {
                if(res.data.auth === true) {
                    setIsLoggedIn(true);
                } else if(res.data.includes("Authenticated")) {
                    setIsLoggedIn(true);
                } else {
                    setIsLoggedIn(false);
                }
            } catch(err) {
                console.log(err);
            }
            
        });
    }
    const logout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("data");
        navigate("/");
    }
    userAuthenticated();


    // DONT DELETE THIS. THIS IS HOW TO GET DATA FROM localStorage
    // try {
    //     const parsedData = JSON.parse(localStorage.getItem("data"));
    //     // console.log(parsedData.data[0]);
    // } catch (err) {
    //     console.log(err);
    // }
    

    return (
        <>
        <nav className='wrapper-parent'>
            <div class="wrapper">
                <Link className='Link' to={"/"}>
                    <div class="logo"><a><img src={Logo} /></a></div>
                </Link>

                <input type="radio" name="slider" id="menu-btn" />
                <input type="radio" name="slider" id="close-btn" />
                <ul class="nav-links">
                    <label for="close-btn" class="btn close-btn"><i class="fas fa-times"></i></label>

                    { isLoggedIn ? (
                        <>  
                            <li><a href="#">Invest</a></li>
                            <li><a href="#">Market</a></li>
                            
                            <li className='tokenize-button'>
                                <Link className='Link' to={"/tokenize"}><a>Tokenize</a></Link>
                            </li>
                            <li>
                                <a href="#" class="desktop-item" style={{lineHeight: "20px"}}>
                                    <Link className='Link' to={"/user-dashboard"}><svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" style={{verticalAlign: "middle", fill: "rgba(255, 255, 255, 1)", transform: ";msFilter:" }}><path d="M12 2C6.579 2 2 6.579 2 12s4.579 10 10 10 10-4.579 10-10S17.421 2 12 2zm0 5c1.727 0 3 1.272 3 3s-1.273 3-3 3c-1.726 0-3-1.272-3-3s1.274-3 3-3zm-5.106 9.772c.897-1.32 2.393-2.2 4.106-2.2h2c1.714 0 3.209.88 4.106 2.2C15.828 18.14 14.015 19 12 19s-3.828-.86-5.106-2.228z"></path></svg></Link>
                                </a>
                                <input type="checkbox" id="showDrop" />
                                <ul class="drop-menu user-drop-menu">
                                    <li>
                                        <span className="account-text">Account</span>
                                    </li>
                                    <li className='level-kyc'>
                                        <span>Level 1 User</span>
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style={{verticalAlign: "middle", marginRight: "4px", fill: "rgba(57, 211, 106, 1)", transform: ";msFilter:"}}><path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"></path><path d="M9.999 13.587 7.7 11.292l-1.412 1.416 3.713 3.705 6.706-6.706-1.414-1.414z"></path></svg>
                                            Verified
                                        </span>
                                        <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style={{verticalAlign: "middle", marginRight: "4px", fill: "rgba(30, 241, 228, 1)", transform: ";msFilter:"}}><path d="M20.995 6.9a.998.998 0 0 0-.548-.795l-8-4a1 1 0 0 0-.895 0l-8 4a1.002 1.002 0 0 0-.547.795c-.011.107-.961 10.767 8.589 15.014a.987.987 0 0 0 .812 0c9.55-4.247 8.6-14.906 8.589-15.014zM12 19.897C5.231 16.625 4.911 9.642 4.966 7.635L12 4.118l7.029 3.515c.037 1.989-.328 9.018-7.029 12.264z"></path><path d="m11 12.586-2.293-2.293-1.414 1.414L11 15.414l5.707-5.707-1.414-1.414z"></path></svg>
                                            Regular Acc
                                        </span>
                                    </li>

                                    <li className='account-details'>
                                        <span className='account-details-title'>Account</span>
                                        <span className='parent-span parent-span-firstChild'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24 24" style={{verticalAlign: "middle", fill: "rgba(84, 76, 76, 1)", transform: ";msFilter:"}}><path d="M12 2a5 5 0 1 0 5 5 5 5 0 0 0-5-5zm0 8a3 3 0 1 1 3-3 3 3 0 0 1-3 3zm9 11v-1a7 7 0 0 0-7-7h-4a7 7 0 0 0-7 7v1h2v-1a5 5 0 0 1 5-5h4a5 5 0 0 1 5 5v1z"></path></svg>                                    
                                            <span className='child-span'>
                                                User Dashboard
                                            </span>
                                        </span>
                                        <span className='parent-span'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24 24" style={{verticalAlign: "middle", fill: "rgba(84, 76, 76, 1)", transform: ";msFilter:"}}><path d="M20.995 6.903a.997.997 0 0 0-.547-.797l-7.973-4a.997.997 0 0 0-.895-.002l-8.027 4c-.297.15-.502.437-.544.767-.013.097-1.145 9.741 8.541 15.008a.995.995 0 0 0 .969-.009c9.307-5.259 8.514-14.573 8.476-14.967zm-8.977 12.944c-6.86-4.01-7.14-10.352-7.063-12.205l7.071-3.523 6.998 3.511c.005 1.87-.481 8.243-7.006 12.217z"></path></svg>
                                            <span className='child-span'>
                                                Security
                                            </span>
                                        </span>
                                        <span className='parent-span'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24 24" style={{verticalAlign: "middle", fill: "rgba(84, 76, 76, 1)", transform: ";msFilter:"}}><path d="M19 13.586V10c0-3.217-2.185-5.927-5.145-6.742C13.562 2.52 12.846 2 12 2s-1.562.52-1.855 1.258C7.185 4.074 5 6.783 5 10v3.586l-1.707 1.707A.996.996 0 0 0 3 16v2a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-2a.996.996 0 0 0-.293-.707L19 13.586zM19 17H5v-.586l1.707-1.707A.996.996 0 0 0 7 14v-4c0-2.757 2.243-5 5-5s5 2.243 5 5v4c0 .266.105.52.293.707L19 16.414V17zm-7 5a2.98 2.98 0 0 0 2.818-2H9.182A2.98 2.98 0 0 0 12 22z"></path></svg>
                                            <span className='child-span'>
                                                Notification
                                            </span>
                                        </span>

                                        <span className='parent-span'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24 24" style={{verticalAlign: "middle", fill: "rgba(84, 76, 76, 1)", transform: ";msFilter:"}}><path d="M12 2C9.243 2 7 4.243 7 7v2H6c-1.103 0-2 .897-2 2v9c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-9c0-1.103-.897-2-2-2h-1V7c0-2.757-2.243-5-5-5zM9 7c0-1.654 1.346-3 3-3s3 1.346 3 3v2H9V7zm9.002 13H13v-2.278c.595-.347 1-.985 1-1.722 0-1.103-.897-2-2-2s-2 .897-2 2c0 .736.405 1.375 1 1.722V20H6v-9h12l.002 9z"></path></svg>
                                            <span className='child-span'>
                                                Account Upgrade
                                            </span>
                                        </span>
                                    </li>

                                    <li className='account-details'>
                                        <span className='account-details-title'>Support & About</span>
                                        <span className='parent-span parent-span-firstChild'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24 24" style={{verticalAlign: "middle", fill: "rgba(84, 76, 76, 1)", transform: ";msFilter:"}}><path d="M20 4H4c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2zM4 18V6h16l.001 12H4z"></path><path d="M6.5 11h3a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 .5.5zM6 14h6v2.001H6zm7 0h5v2.001h-5z"></path></svg>
                                            <span className='child-span'>
                                                Payment Methods
                                            </span>
                                        </span>
                                        <span className='parent-span'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24 24" style={{verticalAlign: "middle", fill: "rgba(84, 76, 76, 1)", transform: ";msFilter:"}}><path d="M12 6a3.939 3.939 0 0 0-3.934 3.934h2C10.066 8.867 10.934 8 12 8s1.934.867 1.934 1.934c0 .598-.481 1.032-1.216 1.626a9.208 9.208 0 0 0-.691.599c-.998.997-1.027 2.056-1.027 2.174V15h2l-.001-.633c.001-.016.033-.386.441-.793.15-.15.339-.3.535-.458.779-.631 1.958-1.584 1.958-3.182A3.937 3.937 0 0 0 12 6zm-1 10h2v2h-2z"></path><path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"></path></svg>
                                            <span className='child-span'>
                                                Help & Support
                                            </span>
                                        </span>
                                        <span className='parent-span'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 16 16" style={{ verticalAlign: "middle" }}><path fill="#544C4C" fill-rule="evenodd" d="M8.429 2.746a.5.5 0 0 0-.858 0L1.58 12.743a.5.5 0 0 0 .429.757h11.984a.5.5 0 0 0 .43-.757zm-2.144-.77C7.06.68 8.939.68 9.715 1.975l5.993 9.996c.799 1.333-.161 3.028-1.716 3.028H2.008C.453 15-.507 13.305.292 11.972l5.993-9.997ZM9 11.5a1 1 0 1 1-2 0a1 1 0 0 1 2 0m-.25-5.75a.75.75 0 0 0-1.5 0v3a.75.75 0 0 0 1.5 0z" clip-rule="evenodd"/></svg>
                                            <span className='child-span'>
                                                Terms & Policies
                                            </span>
                                        </span>
                                    </li>

                                    <li className='account-details'>
                                        <span className='account-details-title'>Actions</span>
                                        <span className='parent-span parent-span-firstChild'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24 24" style={{verticalAlign: "middle", fill: "rgba(84, 76, 76, 1)", transform: ";msFilter:"}}><path d="M19 4H6V2H4v18H3v2h4v-2H6v-5h13a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1zm-1 9H6V6h12v7z"></path></svg>
                                            <span className='child-span'>
                                                Report a Problem
                                            </span>
                                        </span>
                                        <span className='parent-span'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24 24" style={{verticalAlign: "middle", fill: "rgba(84, 76, 76, 1)", transform: ";msFilter:"}}><path d="M19 8h-2v3h-3v2h3v3h2v-3h3v-2h-3zM4 8a3.91 3.91 0 0 0 4 4 3.91 3.91 0 0 0 4-4 3.91 3.91 0 0 0-4-4 3.91 3.91 0 0 0-4 4zm6 0a1.91 1.91 0 0 1-2 2 1.91 1.91 0 0 1-2-2 1.91 1.91 0 0 1 2-2 1.91 1.91 0 0 1 2 2zM4 18a3 3 0 0 1 3-3h2a3 3 0 0 1 3 3v1h2v-1a5 5 0 0 0-5-5H7a5 5 0 0 0-5 5v1h2z"></path></svg>
                                            <span className='child-span'>
                                                Add Account
                                            </span>
                                        </span>
                                        <span className='parent-span' onClick={logout}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24 24" style={{verticalAlign: "middle", fill: "rgba(84, 76, 76, 1)", transform: "rotate(180deg);msFilter:progid:DXImageTransform.Microsoft.BasicImage(rotation=2)"}}><path d="M16 13v-2H7V8l-5 4 5 4v-3z"></path><path d="M20 3h-9c-1.103 0-2 .897-2 2v4h2V5h9v14h-9v-4H9v4c0 1.103.897 2 2 2h9c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2z"></path></svg>
                                            <span className='child-span'>
                                                Logout
                                            </span>
                                        </span>
                                    </li>
                                </ul>
                            </li>
                            
                            <li>
                                <a href="#" class="desktop-item" style={{lineHeight: "20px"}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" style={{verticalAlign: "middle", fill: "rgba(255, 255, 255, 1)", transform: ";msFilter:"}}><path d="M19 13.586V10c0-3.217-2.185-5.927-5.145-6.742C13.562 2.52 12.846 2 12 2s-1.562.52-1.855 1.258C7.185 4.074 5 6.783 5 10v3.586l-1.707 1.707A.996.996 0 0 0 3 16v2a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-2a.996.996 0 0 0-.293-.707L19 13.586zM19 17H5v-.586l1.707-1.707A.996.996 0 0 0 7 14v-4c0-2.757 2.243-5 5-5s5 2.243 5 5v4c0 .266.105.52.293.707L19 16.414V17zm-7 5a2.98 2.98 0 0 0 2.818-2H9.182A2.98 2.98 0 0 0 12 22z"></path></svg>
                                </a>
                                <input type="checkbox" id="showDrop" />
                                <label for="showDrop" class="mobile-item">Dropdown Menu</label>
                                <ul class="drop-menu user-drop-menu noti-drop-menu">
                                    <li>
                                        <span className="account-text">Notifications</span>
                                    </li>
                                    <li className='account-details noti-account-details'>
                                        <span className='parent-span parent-span-firstChild'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" style={{verticalAlign: "middle"}}><path fill="#544C4C" d="M12 8H4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h1v4a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-4h3l5 4V4zm3 7.6L13 14H4v-4h9l2-1.6zm6.5-3.6c0 1.71-.96 3.26-2.5 4V8c1.53.75 2.5 2.3 2.5 4"/></svg>
                                            <span className='child-span'>
                                                Announcement 
                                            </span>
                                            <span className='hover-arrow'>&#x21e5;</span>
                                            <span className='child-span noti-child-span'>
                                                Announcement notification sent by Many Markup...
                                            </span>
                                        </span>
                                    </li>
                                    <li className='account-details noti-account-details'>
                                        <span className='parent-span parent-span-firstChild'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" style={{verticalAlign: "middle"}}><path fill="#544C4C" d="M6.455 19L2 22.5V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1zm-.692-2H20V5H4v13.385zM8 10h8v2H8z"/></svg>
                                            <span className='child-span'>
                                                System Message
                                            </span>
                                            <span className='hover-arrow'>&#x21e5;</span>
                                            <span className='child-span noti-child-span'>
                                                System message notification sent by Many Markup...
                                            </span>
                                        </span>
                                    </li>
                                </ul>
                            </li>

                            <li>
                                <a href="#" class="desktop-item" style={{lineHeight: "20px"}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" style={{verticalAlign: "middle", fill: "rgba(255, 255, 255, 1)", transform: ";msFilter:"}}><path d="M12 8v5h5v-2h-3V8z"></path><path d="M21.292 8.497a8.957 8.957 0 0 0-1.928-2.862 9.004 9.004 0 0 0-4.55-2.452 9.09 9.09 0 0 0-3.626 0 8.965 8.965 0 0 0-4.552 2.453 9.048 9.048 0 0 0-1.928 2.86A8.963 8.963 0 0 0 4 12l.001.025H2L5 16l3-3.975H6.001L6 12a6.957 6.957 0 0 1 1.195-3.913 7.066 7.066 0 0 1 1.891-1.892 7.034 7.034 0 0 1 2.503-1.054 7.003 7.003 0 0 1 8.269 5.445 7.117 7.117 0 0 1 0 2.824 6.936 6.936 0 0 1-1.054 2.503c-.25.371-.537.72-.854 1.036a7.058 7.058 0 0 1-2.225 1.501 6.98 6.98 0 0 1-1.313.408 7.117 7.117 0 0 1-2.823 0 6.957 6.957 0 0 1-2.501-1.053 7.066 7.066 0 0 1-1.037-.855l-1.414 1.414A8.985 8.985 0 0 0 13 21a9.05 9.05 0 0 0 3.503-.707 9.009 9.009 0 0 0 3.959-3.26A8.968 8.968 0 0 0 22 12a8.928 8.928 0 0 0-.708-3.503z"></path></svg>
                                </a>
                            </li>
                        </>
                    ) : (
                        <>
                        
                            <li><Link className='Link' to={"/auth"}><a>Invest</a></Link></li>
                            
                            <li><Link className='Link' to={"/auth"}><a>Market</a></Link></li>
                            <li className='tokenize-button'><Link to={"/auth"}><a>Tokenize</a></Link></li>
                            <li>
                                <Link className='Link' to={"/auth"}>
                                <a class="desktop-item" style={{lineHeight: "20px"}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" style={{verticalAlign: "middle", fill: "rgba(255, 255, 255, 1)", transform: ";msFilter:" }}><path d="M12 2C6.579 2 2 6.579 2 12s4.579 10 10 10 10-4.579 10-10S17.421 2 12 2zm0 5c1.727 0 3 1.272 3 3s-1.273 3-3 3c-1.726 0-3-1.272-3-3s1.274-3 3-3zm-5.106 9.772c.897-1.32 2.393-2.2 4.106-2.2h2c1.714 0 3.209.88 4.106 2.2C15.828 18.14 14.015 19 12 19s-3.828-.86-5.106-2.228z"></path></svg>
                                </a>
                                </Link>
                            </li>
                            
                            <li>
                                <Link className='Link' to={"/auth"}>
                                <a href="#" class="desktop-item" style={{lineHeight: "20px"}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" style={{verticalAlign: "middle", fill: "rgba(255, 255, 255, 1)", transform: ";msFilter:"}}><path d="M19 13.586V10c0-3.217-2.185-5.927-5.145-6.742C13.562 2.52 12.846 2 12 2s-1.562.52-1.855 1.258C7.185 4.074 5 6.783 5 10v3.586l-1.707 1.707A.996.996 0 0 0 3 16v2a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-2a.996.996 0 0 0-.293-.707L19 13.586zM19 17H5v-.586l1.707-1.707A.996.996 0 0 0 7 14v-4c0-2.757 2.243-5 5-5s5 2.243 5 5v4c0 .266.105.52.293.707L19 16.414V17zm-7 5a2.98 2.98 0 0 0 2.818-2H9.182A2.98 2.98 0 0 0 12 22z"></path></svg>
                                </a>
                                </Link>
                            </li>

                            <li>
                                <Link className='Link' to={"/auth"}>
                                <a href="#" class="desktop-item" style={{lineHeight: "20px"}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" style={{verticalAlign: "middle", fill: "rgba(255, 255, 255, 1)", transform: ";msFilter:"}}><path d="M12 8v5h5v-2h-3V8z"></path><path d="M21.292 8.497a8.957 8.957 0 0 0-1.928-2.862 9.004 9.004 0 0 0-4.55-2.452 9.09 9.09 0 0 0-3.626 0 8.965 8.965 0 0 0-4.552 2.453 9.048 9.048 0 0 0-1.928 2.86A8.963 8.963 0 0 0 4 12l.001.025H2L5 16l3-3.975H6.001L6 12a6.957 6.957 0 0 1 1.195-3.913 7.066 7.066 0 0 1 1.891-1.892 7.034 7.034 0 0 1 2.503-1.054 7.003 7.003 0 0 1 8.269 5.445 7.117 7.117 0 0 1 0 2.824 6.936 6.936 0 0 1-1.054 2.503c-.25.371-.537.72-.854 1.036a7.058 7.058 0 0 1-2.225 1.501 6.98 6.98 0 0 1-1.313.408 7.117 7.117 0 0 1-2.823 0 6.957 6.957 0 0 1-2.501-1.053 7.066 7.066 0 0 1-1.037-.855l-1.414 1.414A8.985 8.985 0 0 0 13 21a9.05 9.05 0 0 0 3.503-.707 9.009 9.009 0 0 0 3.959-3.26A8.968 8.968 0 0 0 22 12a8.928 8.928 0 0 0-.708-3.503z"></path></svg>
                                </a>
                                </Link>
                            </li>
                            
                        </>
                    ) }
                    
                </ul>
                <label for="menu-btn" class="btn menu-btn"><i class="fas fa-bars"></i></label>
            </div>
        </nav>
        </>
    )
}

export default TopNavbar