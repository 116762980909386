import React, { useEffect, useState } from 'react';
import './ProjectDetails.css';
import { useLocation, useNavigate } from 'react-router-dom';
import Logo from '../../images/logo.png';
import ProjectDetailsDesc from './ProjectDetailsDesc';
import axios from 'axios';
import Party from '../../images/party.png';

const ProjectDetails = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { projectId } = location.state;

    const [projectDetailData, setProjectDetailData] = useState([]);
    const [projectImages, setProjectImages] = useState([]);
    const [showInvestThisOffer, setShowInvestThisOffer] = useState(false);
    const [cantInvestMoreThanMaxTokens, setCantInvestMoreThanMaxTokens] = useState(false);
    const [investSuccessfully, setInvestSuccessfully] = useState(false);
    const [mustInvestMinimum, setMustInvestMinimum] = useState(false);
    const [investorId, setInvestorId] = useState();

    const [sellerImage, setSellerImage] = useState();
    const [sellerName, setSellerName] = useState();

    // const [tax, setTax] = useState(0);
    const [total, setTotal] = useState(0);
    const [totalBoughtTokens, setTotalBoughtTokens] = useState(0);
    const [discountCode, setDiscountCode] = useState();

    const [serviceFees, setServiceFees] = useState(2);
    

    const handleTotalAmountChange = (e) => {
        const newTotalAmount = e.target.value;
        const pricePerToken = projectDetailData[0]['pricePerToken'];
        const discount = projectDetailData[0]['assetMarketValueDiscount'];
        
        if(discountCode) {
            setServiceFees(1);
            const total = newTotalAmount * pricePerToken; // Original principal amount
            const serviceFeesToDeduct = (total * serviceFees) / 100;
            const discountDeducted = (total * discount) / 100; // Deduct discount from the original principal
            
            const grandTotal = total + serviceFeesToDeduct - discountDeducted; // Add service fees and subtract discount
            
            setTotalBoughtTokens(newTotalAmount);
            setTotal(grandTotal);
        } else  {
            const total = newTotalAmount * pricePerToken; // Original principal amount
            const serviceFeesToDeduct = (total * serviceFees) / 100;
            const discountDeducted = (total * discount) / 100; // Deduct discount from the original principal
            
            const grandTotal = total + serviceFeesToDeduct - discountDeducted; // Add service fees and subtract discount
            
            setTotalBoughtTokens(newTotalAmount);
            setTotal(grandTotal);
        }
    };

    useEffect(() => {
        axios.post("http://localhost:8081/get_project_details", {projectId: projectId}).then((res) => {
            setProjectDetailData(res.data.data);
        });

        const parsedData = JSON.parse(localStorage.getItem("data"));
        const userData = parsedData.data[0];
        setInvestorId(userData.id);
        setSellerImage(userData.user_image);
        setSellerName(userData.name);
        setDiscountCode(userData.discount_code);

        if(localStorage.getItem("token")) {
            return;
        } else {
            navigate('/auth');
        }
    }, []);

    const investThisOffer = () => {
        setShowInvestThisOffer(true);
    }

    const closeInvestThisOfferModal = () => {
        setShowInvestThisOffer(false);
    }

    const closeCantInvestMoreThanMaxTokensModal = () => {
        setCantInvestMoreThanMaxTokens(false);
    }

    const closeInvestSuccessfullyModal = () => {
        setInvestSuccessfully(false);
        setShowInvestThisOffer(false);
    }

    const closeMustInvestAtLeastMinimumsModal = () => {
        setMustInvestMinimum(false);
    }
    
    const realInvestThisOffer = (e) => {
        e.preventDefault();

        if(totalBoughtTokens > projectDetailData[0]['maxTokens']) {
            setCantInvestMoreThanMaxTokens(true);
            return;
        }

        if(totalBoughtTokens < projectDetailData[0]['minimumPurchaseRequirement']) {
            setMustInvestMinimum(true);
            return;
        }

        const requestData = {
            projectId: projectId,
            boughtTokensAmount: totalBoughtTokens * projectDetailData[0]['pricePerToken'],
            investorId: investorId,
            sellerImage: sellerImage,
            sellerName: sellerName,
            primaryDeal: projectDetailData[0]['projectName'],
            projectSubType: projectDetailData[0]['projectSubType'],
            RegionOfOrigin: projectDetailData[0]['regionOfOrigin'],
            incoterms: projectDetailData[0]['incoterms'],
            underlyingValue: totalBoughtTokens * projectDetailData[0]['pricePerToken'],
            category: projectDetailData[0]['investmentCategory'],
            type: projectDetailData[0]['typeOfInvestment'],
        };

        try {
            axios.post("http://localhost:8081/invest_this_project", requestData).then((res) => {
                // console.log(res.data);
                setInvestSuccessfully(true);
            });
        } catch (err) {
            // console.log(err);
        }
    }

    return (
        <>
            {
                projectDetailData.map((item) => (
                    <div className='parent-parent-project-details-page' key={item.id}>
                        <p className='title'>{item.projectName}</p>
                        <p className='proposing-person'>Proposed By: <span className='user-name'>{item.projectOwner}</span></p>

                        <div className='parent-project-details-page'>
                            <div className='child1'>
                                <img src={`http://localhost:8081/${item.projectPhoto.replace(/^public\/?|\/?$/g, "")}`} alt="" />
                            </div>

                            <div className='child2'>
                                <div className='line'></div>
                                <div className='child2-first-row'>
                                    <p className='child2-first-row-title'>US ${(item.maxTokens * item.pricePerToken).toLocaleString()}</p>
                                    <p className='child2-first-row-text'>pledged of US ${(item.maxTokens * item.pricePerToken).toLocaleString()} goal</p>
                                </div>
                                
                                <div className='parent-kpi-div'>
                                    <div>
                                        <p className='kpi-value'>{(item.maxTokens).toLocaleString()}</p>
                                        <p className='kpi-title'>Max Token Supply</p>
                                    </div>

                                    <div>
                                        <p className='kpi-value'>{item.regionOfOrigin}</p>
                                        <p className='kpi-title'>Region Of Origin</p>
                                    </div>

                                    <div>
                                        <p className='kpi-value'>{item.incoterms}</p>
                                        <p className='kpi-title'>Incoterm</p>
                                    </div>

                                    <div>
                                        <p className='kpi-value'>{(item.pricePerToken * 100000).toLocaleString()}</p>
                                        <p className='kpi-title'>Expected Return Per 100k Tokens</p>
                                    </div>

                                    <div>
                                        <p className='kpi-value'>{(item.minimumPurchaseRequirement).toLocaleString()}</p>
                                        <p className='kpi-title'>Minimum Investment</p>
                                    </div>

                                    <div>
                                        <p className='kpi-value'>N/A</p>
                                        <p className='kpi-title'>Pay Frequency</p>
                                    </div>
                                </div>

                                <button className='invest-button' onClick={investThisOffer}>Invest This Offer</button>

                                <div className='verified-div'>
                                    <div className="verified-div-child1">
                                        <img src={Logo} alt="" />
                                    </div>
                                    <div className="verified-div-child2">
                                        <p className='verified-title'>Verification Passed</p>
                                        <p className='verified-text'>This project has been legally & financially verified by ManyMarkup</p>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>

                        <div className='parent-two-tags-div'>
                            <div className='two-tags-div'>
                                <span>{item.investmentCategory}</span>
                                <span className='two-tags-div-text'>{item.typeOfInvestment}</span>
                            </div>
                        </div>

                        <ProjectDetailsDesc title={item.projectDescriptionTitle} description={item.projectDescription} 
                        certificatePhotos={item.certificatePhotos} galleryPhotos={item.galleryPhotos} docs={item.docs} />


                        {/* Invest This Offer Modal */}
                        <div className="parent-parent-invest-failed" style={{ display: showInvestThisOffer ? 'inline-block' : 'none' }}>
                            <div className="parent-invest-failed">
                                <div className="svg-parent">
                                    <img className='investPhoto' src={`http://localhost:8081/${item.projectPhoto.replace(/^public\/?|\/?$/g, "")}`} alt="" />
                                </div>
                                <div className='invest-data'>
                                    <p className="title">{item.projectName}</p>
                                    <input onChange={handleTotalAmountChange} 
                                        className='first-input' type="text" placeholder='Total token amount to invest' />
                                    
                                        {

                                        discountCode && <input onChange={handleTotalAmountChange} type="text" value={`Discount Code: ${discountCode}`} disabled />
                                        
                                        }
                                    

                                    <input type="text" value={`Service Fees: ${serviceFees}%`} disabled />
                                    <input type="text" value={`Discount: ${item.assetMarketValueDiscount}%`} disabled />
                                    <input type="text" value={`Total: $${total.toLocaleString()} USD`} disabled />

                                    <div className='two-buttons-invest-modal'>
                                        <button className='closeInvestButton' onClick={realInvestThisOffer}>Got It</button>
                                        <button className='closeInvestButton cancelInvestButton' onClick={closeInvestThisOfferModal}>CANCEL</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Cannot Invest More Than Max Token Modal */}
                        <div className="parent-parent-maxToken-failed" style={{ display: cantInvestMoreThanMaxTokens ? 'inline-block' : 'none' }}>
                            <div className="parent-maxToken-failed">
                                <div className="svg-parent">
                                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#f00"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M16 8L8 16M12 12L16 16M8 8L10 10M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#f00" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                                </div>
                                <p className="title">Can't Invest More Than Max Token!</p>
                                <p className="text">You can't invest more than {(item.maxTokens).toLocaleString()} tokens in this {item.projectName}</p>
                                <button className='button' onClick={closeCantInvestMoreThanMaxTokensModal}>Got It</button>
                            </div>
                        </div>

                        {/* Investment Success Modal */}
                        <div className="parent-parent-investment-success" style={{ display: investSuccessfully ? 'inline-block' : 'none' }}>
                            <div className="parent-investment-success">
                                <div className="svg-parent">
                                <img src={Party} alt="" />
                                </div>
                                <p className="title">Investment Successful!</p>
                                <p className="text">You have successfully contributed in {item.projectName}. Your investment amount is ${(total).toLocaleString()}USD.</p>
                                <button className='button' onClick={closeInvestSuccessfullyModal}>Got It</button>
                            </div>
                        </div>

                        {/* Must invest at least minimum Modal */}
                        <div className="parent-parent-maxToken-failed" style={{ display: mustInvestMinimum ? 'inline-block' : 'none' }}>
                            <div className="parent-maxToken-failed">
                                <div className="svg-parent">
                                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#f00"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M16 8L8 16M12 12L16 16M8 8L10 10M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#f00" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                                </div>
                                <p className="title">Investment Too Small!</p>
                                <p className="text">You must invest at least {(item.minimumPurchaseRequirement).toLocaleString()} tokens in this {item.projectName}</p>
                                <button className='button' onClick={closeMustInvestAtLeastMinimumsModal}>Got It</button>
                            </div>
                        </div>
                    </div>
                ))
            }
        </>
    )
}

export default ProjectDetails