import React, { useEffect, useState } from 'react';
import './markets.css';
import PrimaryMarket from '../PrimaryMarket';
import MUIDataTable from 'mui-datatables';
import { createTheme, ThemeProvider } from '@mui/material';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import ContractMarket from '../ContractMarket';
import Party from '../../images/party.png';

const Markets = () => {
    const [secondaryMarketData, setSecondaryMarketData] = useState([]);
    const [secondaryProjectId, setSecondaryProjectId] = useState();
    const [showBid, setShowBid] = useState(false);
    const [bidValue, setBidValue] = useState();
    const [userID, setUserID] = useState();
    const [userName, setUserName] = useState();
    const [bidSuccess, setBidSuccess] = useState(false);

    const [selectedTab, setSelectedTab] = useState('');

    useEffect(() => {
        try {
            const data = localStorage.getItem('data');
            if (data) {
                const parsedData = JSON.parse(localStorage.getItem("data"));
                const userData = parsedData.data[0];
                const userID = userData.id;
                const userName = userData.name;

                setUserID(userID);
                setUserName(userName)

                axios.get('http://localhost:8081/get_all_secondary_market_data').then((res) => {
                    const flattenedData = res.data.data.map(obj =>
                    Object.values(obj).map(value => (value === null || value === '' ? 0 : value))
                    );
                    setSecondaryMarketData(flattenedData);
                });
            } else {
            Navigate('/');
            }
        } catch (err) {
            console.log(err);
        }
    }, []);

    useEffect(() => {
        // Set the initial selected tab to tab-1
        setSelectedTab('tab-1');
    }, []);

    const handleTabChange = (e) => {
        setSelectedTab(e.target.id);
    };

    const secondaryMarketColumns = [
        {
          name: 'Date',
          options: {
              display: false, // Hide the 'Date' column
          },
      },
        {
            name: 'ID',
            options: {
                display: false, // Hide the 'Date' column
            },
        },
        {
          name: 'Seller', // Assuming 'Seller' column contains the image URL
          options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                  const imageUrl = tableMeta.rowData[2]; // Assuming 'Seller' column contains the image URL
                  return <img src={`http://localhost:8081/${imageUrl.replace(/^public\/?|\/?$/g, "")}`} alt="Seller Image" className='seller-pic' />;
              },
              setCellProps: () => ({ style: { minWidth: '150px', textAlign: 'center' } })
            },
        },
        {
            name: 'Seller Name',
            options: {
                setCellProps: () => ({ style: { minWidth: '180px', textAlign: 'center' } })
            }
        },
        {
            name: 'Primary Deal',
            options: {
                setCellProps: () => ({ style: { minWidth: '190px', textAlign: 'center' } })
            }
        },
        {
            name: 'Sub Type',
            options: {
                setCellProps: () => ({ style: { minWidth: '180px', textAlign: 'center' } })
            }
        },
        {
            name: 'Incoterms',
            options: {
                setCellProps: () => ({ style: { minWidth: '180px', textAlign: 'center' } })
            }
        },
        {
            name: 'Region of Origin',
            options: {
                setCellProps: () => ({ style: { minWidth: '160px', textAlign: 'center' } })
            }
        },
        {
          name: 'Underlying Value',
          options: {
              customBodyRender: (value) => value.toLocaleString(), // Format the 'Underlying Value'
              setCellProps: () => ({ style: { minWidth: '170px', textAlign: 'center' } })
          },
        },
        {
            name: "Category",
            options: {
                customBodyRender: (value) => (
                    <a href={value} target='_blank' className='tag-text'>{value}</a>
                ),
                setCellProps: () => ({ style: { minWidth: '180px', textAlign: 'center' } })
            }
        },
        {
          name: "Type",
          options: {
            customBodyRender: (value) => (
              <a href={value} target='_blank' className='tag-text'>{value}</a>
            ),
            setCellProps: () => ({ style: { minWidth: '180px', textAlign: 'center' } })
          }
        },
        {
          name: 'Seller Ask',
          options: {
              customBodyRender: (value) => value.toLocaleString(), // Format the 'Seller Ask'
              setCellProps: () => ({ style: { minWidth: '180px', textAlign: 'center' } })
          },
        },
        {
          name: 'Highest Bids',
          options: {
            setCellProps: () => ({ style: { minWidth: '180px', textAlign: 'center' } }),
            customBodyRender: (value) => {
                if(value) {
                    const convertedHighestBid = JSON.parse(value);
                    let highestBidValue = 0;
                    
                    convertedHighestBid.forEach(bid => {
                        const bidValue = parseInt(bid[2]);
                        if (bidValue > highestBidValue) {
                        highestBidValue = bidValue;
                        }
                    });
                    // console.log("Highest bid value:", highestBidValue);
                    return highestBidValue.toLocaleString();
                } else {
                    return "No Bidder Yet";
                }
            }, // Format the 'Highest Bids'
          },
      },
      {
        name: 'Bid', // Assuming 'Seller' column contains the image URL
        options: {
            setCellProps: () => ({ style: { minWidth: '180px', textAlign: 'center' } }),
            customBodyRender: (value, tableMeta, updateValue) => {
              const passDataToMakeSecondary = () => {
                const projectId = tableMeta.rowData[0];
                setSecondaryProjectId(projectId);
                showBidModal();
              }
              return <button className='bid-button' onClick={passDataToMakeSecondary}>Bid</button>
            },
        },
    }];

    const options = {
        rowsPerPage: 5,
        rowsPerPageOptions: [5],
        selectableRows: false,
        print: false,
        search: false,
        scroll: false,
        download: false,
        responsive: 'scrollMaxHeight',
        customHeadRender: (columnMeta, handleToggleColumn) => {
          return (
            <th key={columnMeta.index} style={{ whiteSpace: 'nowrap', minWidth: '100px' }}>
                  {columnMeta.label}
            </th>
            );
        },
    };

    const tableContainerStyle  = {
        width: '100%',
    };

    const getMuiTheme = () => createTheme({
        palette: {
            background: {
              paper: "#212121",
              default: "#212121",
            },
            mode: 'dark',
        },
        components: {
            MuiTableCell: {
              styleOverrides: {
                root: {
                  fontSize: '12px'
                },
              },
            },
        },
    });

    const showBidModal = () => {
        setShowBid(true);
    }
    
    const closeBidModal = () => {
        setShowBid(false);
    }
    
    const handleBidValue = (e) => {
        const bidValue = e.target.value;
        setBidValue(bidValue);
    }
    
    const bidNow = () => {
        axios.post('http://localhost:8081/bid_the_secondary_project', 
            { userID: userID, userName: userName, bidValue: bidValue, secondaryProjectId: secondaryProjectId }).then((res) => {
            if(res.data.success) {
                setBidSuccess(true);
            }
        });
    }

    const closeBidSuccessModal = () => {
        setBidSuccess(false);
        setShowBid(false);
    }

    return (
        <>  
            <div align="center" style={{ marginTop: '5%', marginBottom: '3%' }}>
                <h2 style={{ fontSize: "2.25rem" }}>Markets</h2>
            </div>

            {/* Bid Modal */}
            <div className="parent-parent-invest-failed" style={{ display: showBid ? 'inline-block' : 'none' }}>
                <div className="parent-invest-failed">
                <div className='invest-data'>
                    <p className="title">Bid This Project</p>
                    <input onChange={handleBidValue} 
                    className='first-input' type="text" placeholder='How much you will bid for this project' />

                    <div className='two-buttons-invest-modal'>
                    <button className='closeInvestButton' onClick={bidNow}>Got It</button>
                    <button className='closeInvestButton cancelInvestButton' onClick={closeBidModal}>CANCEL</button>
                    </div>
                </div>
                </div>
            </div>

            {/* Bid Success Modal */}
            <div className="parent-parent-invest-failed" style={{ display: bidSuccess ? 'inline-block' : 'none' }}>
                <div className="parent-invest-failed">
                <div className='invest-data'>
                <div className="svg-parent">
                    <img src={Party} alt="" />
                </div>
                    <p className="title">Congratulations!</p>
                    <p className="text">You have successfully bid the project in the secondary market.</p>
                    <button className='gotItButton' onClick={closeBidSuccessModal}>GOT IT</button>
                </div>
                </div>
            </div>
            
            <div className='market-parent-wrapper'>
                <div class="market-wrapper">
                    <div class="market-container">
                        <div class="market-card market-tabs">
                            <input 
                                id="tab-1" 
                                type="radio" 
                                className="tab tab-selector" 
                                name="tab" 
                                checked={selectedTab === 'tab-1'} 
                                onChange={handleTabChange}
                            />
                            <label for="tab-1" class="tab tab-primary">Primary Market</label>

                            <input 
                                id="tab-2" 
                                type="radio" 
                                className="tab tab-selector" 
                                name="tab" 
                                checked={selectedTab === 'tab-2'} 
                                onChange={handleTabChange}
                            />
                            <label for="tab-2" class="tab tab-success">Secondary Market</label>

                            <input 
                                id="tab-3" 
                                type="radio" 
                                className="tab tab-selector" 
                                name="tab" 
                                checked={selectedTab === 'tab-3'} 
                                onChange={handleTabChange}
                            />
                            <label for="tab-3" class="tab tab-default">Contract Market</label>

                            <div class="tabsShadow"></div>
                            <div class="glider"></div>

                            <section class="content" style={{ paddingBottom: "90px" }}>
                                <div class="item" id="content-1">
                                    <PrimaryMarket />
                                </div>

                                {/* Secondary Market */}
                                <div class="item" id="content-2">
                                    <div className='parent-secondary-market'>
                                        <ThemeProvider theme={getMuiTheme()}>
                                        <MUIDataTable
                                            title={"Secondary Market"}
                                            data={secondaryMarketData}
                                            columns={secondaryMarketColumns}
                                            options={options}
                                        />
                                        </ThemeProvider>
                                    </div>
                                </div>

                                <div class="item" id="content-3">
                                    <ContractMarket />
                                </div>
                            </section>
                    
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Markets