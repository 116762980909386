import React from 'react';
import './rap.css';
import Slider from 'react-slick';
import Office from '../../images/office.jpg';
import Cargo from '../../images/cargo.jpg';
import Wheat from '../../images/wheat.jpg';
import Greece from '../../images/greece.jpg';

const RecentlyAddedProjects = () => {
    const trendingSettings = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false,
    };

    return (
        <>  
            <div align="center" style={{ marginTop: '2%' }}>
                <h2 style={{ fontSize: "2.25rem" }}>Recently Added Projects</h2>
            </div>
            <Slider {...trendingSettings}>
                <div className='rap-parent-parent-card'>
                    <div className='rap-parent-card'>
                        
                        <article class="card">
                            <img
                                class="card__background"
                                src={Greece}
                                alt=""
                            />
                            <div class="card__content | flow">
                                <div class="card__content--container | flow">
                                <h2 class="card__title">T. Service</h2>
                                    <p class="card__description">
                                    Converting service offerings into digital tokens, enabling innovative investment opportunities, streamlined transactions, and enhanced access to a global market.
                                    </p>
                                </div>
                                <button class="card__button">Read more</button>
                            </div>
                        </article>
                    </div>
                </div>

                <div className='rap-parent-parent-card'>
                    <div className='rap-parent-card'>
                        
                        <article class="card">
                            <img
                                class="card__background"
                                src={Office}
                                alt=""
                            />
                            <div class="card__content | flow">
                                <div class="card__content--container | flow">
                                <h2 class="card__title">T. Real Estate</h2>
                                <p class="card__description">
                                    Transforming property ownership into digital tokens, offering increased liquidity, fractional ownership, and enhanced investment accessibility for investors worldwide.
                                </p>
                                </div>
                                <button class="card__button">Read more</button>
                            </div>
                        </article>
                    </div>
                </div>

                <div className='rap-parent-parent-card'>
                    <div className='rap-parent-card'>
                        
                        <article class="card">
                            <img
                                class="card__background"
                                src={Cargo}
                                alt=""
                            />
                            <div class="card__content | flow">
                                <div class="card__content--container | flow">
                                <h2 class="card__title">Tokenized Trade</h2>
                                <p class="card__description">
                                    Digitizing trade assets into tokens, facilitating secure, transparent, and efficient transactions, and unlocking new avenues for global commerce and investment.
                                </p>
                                </div>
                                <button class="card__button">Read more</button>
                            </div>
                        </article>
                    </div>
                </div>

                <div className='rap-parent-parent-card'>
                    <div className='rap-parent-card'>
                        
                        <article class="card">
                            <img
                                class="card__background"
                                src={Wheat}
                                alt=""
                            />
                            <div class="card__content | flow">
                                <div class="card__content--container | flow">
                                <h2 class="card__title">T. Commodity</h2>
                                <p class="card__description">
                                Revolutionizing the commodity market by converting physical assets into digital tokens, enhancing liquidity, transparency, and accessibility for investors and traders globally.
                                </p>
                                </div>
                                <button class="card__button">Read more</button>
                            </div>
                        </article>
                    </div>
                </div>
            </Slider>
        </>
    )
}

export default RecentlyAddedProjects