import React from 'react';
import './footer.css';
import logo from '../../images/logo.png'

const Footer = () => {
  return (
    <div className='parent-footer' align="center">
        <section className="child-section w-full flex justify-center mt-60">
            <footer className="w-full">
                <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
                    <div className="sm:flex sm:items-center sm:justify-between">
                        <a href="#" className="flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse">
                            <img src={logo}
                            width="60"
                            height="60"
                            alt="" />
                            <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">ManyMarkup</span>
                        </a>
                        <ul className="flex flex-wrap items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
                            <li>
                                <a href="#" className="hover:underline me-4 md:me-6">Invest</a>
                            </li>
                            <li>
                                <a href="#" className="hover:underline me-4 md:me-6">Market</a>
                            </li>
                            <li>
                                <a href="#" className="hover:underline me-4 md:me-6">Account</a>
                            </li>
                            <li>
                                <a href="#" className="hover:underline">Tokenize</a>
                            </li>
                        </ul>
                    </div>
                    <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
                    <span className="block text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2024 <a href="https://manymarkup.com/" className="hover:underline">ManyMarkup</a>. All Rights Reserved.</span>
                </div>
            </footer>
        </section>
      </div>
    // <div className='footer-parent-parent'>
    //     <div className='footer-parent'>
    //         <footer class="footer">
    //             <div class="waves">
    //                 <div class="wave" id="wave1"></div>
    //                 <div class="wave" id="wave2"></div>
    //                 <div class="wave" id="wave3"></div>
    //                 <div class="wave" id="wave4"></div>
    //             </div>
    //             {/* <ul class="social-icon">
    //                 <li class="social-icon__item"><a class="social-icon__link" href="#">
    //                     <ion-icon name="logo-facebook"></ion-icon>
    //                     </a></li>
    //                 <li class="social-icon__item"><a class="social-icon__link" href="#">
    //                     <ion-icon name="logo-twitter"></ion-icon>
    //                     </a></li>
    //                 <li class="social-icon__item"><a class="social-icon__link" href="#">
    //                     <ion-icon name="logo-linkedin"></ion-icon>
    //                     </a></li>
    //                 <li class="social-icon__item"><a class="social-icon__link" href="#">
    //                     <ion-icon name="logo-instagram"></ion-icon>
    //                     </a></li>
    //             </ul> */}
    //             <ul class="menu">
    //                 <li class="menu__item"><a class="menu__link" href="#">Home</a></li>
    //                 <li class="menu__item"><a class="menu__link" href="#">Invest</a></li>
    //                 <li class="menu__item"><a class="menu__link" href="#">Market</a></li>
    //             </ul>
    //             <p>&copy;2024 Many Markup | All Rights Reserved</p>
    //         </footer>
    //     </div>
    // </div>
  )
}

export default Footer