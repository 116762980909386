import React from 'react';
import './WrapperForAppJS.css';

const index = (props) => {
  return (
    <div className='wrapper'>
        {props.children}
    </div>
  )
}

export default index