import React, { useEffect, useState } from 'react';
import './PrimaryMarket.css';
import Logo from '../../images/logo.png';
import axios from 'axios';
import { Link } from 'react-router-dom';
import styled, { css, keyframes } from 'styled-components';

// This component is used in market
const PrimaryMarket = () => {

    const [projects, setProjects] = useState([]);
    useEffect(() => {
        axios.get('http://localhost:8081/get_tokenized_projects').then((res) => {
            setProjects(res.data.data);
        });
    }, []);
    

    function limitWords(text) {
        // Input validation (handle non-string input gracefully)
        if (typeof text !== 'string') {
            return 'Invalid input: Please provide a string.';
        }
    
        // Efficiently split into word array and handle multiple spaces
        const words = text.trim().replace(/\s+/g, ' ').split(' ');
    
        // Take words up to limit, handling edge cases and ensuring clean output
        const limitedWords = words.slice(0, 21).join(' ');
        const isTruncated = words.length > 21; // Flag for adding ellipsis
    
        return isTruncated ? `${limitedWords}...` : limitedWords;
    }
    

    function limitPrecent(value) {
        return `${value.toFixed(2)} %`;
    }

    const loadAnimation = (width) => keyframes`
        0% { width: 0%; }
        100% { width: ${width}%; }
    `;

    const StyledProgressValue = styled.div`
    animation: ${props => css`${loadAnimation(props.width)} 3s normal forwards`};
        border-radius: 100px;
        background: #f6d36a;
        box-shadow: 0px 0px 7px #f6d36a;
        height: 10px;
        width: 0;
    `;

    const formatNumber = (num) => {
        if (num >= 1e9) {
          return (num / 1e9).toFixed(2) + 'B';
        } else if (num >= 1e6) {
          return (num / 1e6).toFixed(2) + 'M';
        } else if (num >= 1e3) {
          return (num / 1e3).toFixed(2) + 'K';
        } else {
          return num.toString();
        }
    };

    const removeHtmlTags = (htmlString) => {
        const cleanString = htmlString.replace(/<[^>]*>/g, '');
        return cleanString;
    };
    
    return (
        <>
            {/* First Card */}
            {
                projects.map((item) => (
                    <div className="parent-standard-card">
                        <div class="parent-pm-center">
                            <div class="pm-center">
                                <div class="article-card">
                                    <div class="content investment-type">
                                        <p class="title investment-type-text">{item.investmentCategory}</p>
                                    </div>
                                    <div class="content">
                                        <p class="title"><b>{item.projectName}</b></p>
                                        <p class="title"><b>{item.projectSubType}</b></p>
                                        <p class="date">Proposed By <b>{item.projectOwner}</b></p>
                                  </div>
                                    <img src={`http://localhost:8081/${item.projectPhoto.replace(/^public\/?|\/?$/g, "")}`} alt="article-cover" />
                                </div>
                            </div>
                            <div className="parent-project-details">
                                {/* This is the first child */}
                                <div class="project-details-5-child project-detail-1-child">
                                    <div className='yields-parent'>
                                        <div className='yields-children first-child'>
                                            <p className='desc'>Raised So Far</p>
                                            <p className='value'>${formatNumber(item.raised_so_far)}
                                                <span> {limitPrecent(((item.raised_so_far / (item.maxTokens * item.pricePerToken)))*100)}</span>
                                            </p>
                                            
                                        </div>
                                        <div className='yields-children last-child'>
                                            <p className='desc'>Target</p>
                                            <p className='value'>${formatNumber(item.maxTokens * item.pricePerToken)}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className='project-details-2-child'>
                                    <div class="progress">
                                        <StyledProgressValue width={((item.raised_so_far / (item.maxTokens * item.pricePerToken)))*100}></StyledProgressValue>
                                    </div>
                                </div>

                                <div class="project-details-3-child">
                                    <div className='svg-span'>
                                        <div className='child-svg-span'>
                                            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="6" r="4" stroke="#ADADAD" stroke-width="1.5"></circle> <path d="M19.9975 18C20 17.8358 20 17.669 20 17.5C20 15.0147 16.4183 13 12 13C7.58172 13 4 15.0147 4 17.5C4 19.9853 4 22 12 22C14.231 22 15.8398 21.8433 17 21.5634" stroke="#ADADAD" stroke-width="1.5" stroke-linecap="round"></path> </g></svg>
                                            <span>-</span>
                                        </div>
                                    </div>

                                    <div className='svg-span second-child'>
                                        <div className='child-svg-span'>
                                            <svg fill="#ADADAD" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-7.59V4h2v5.59l3.95 3.95-1.41 1.41L9 10.41z"></path></g></svg>
                                            <span>N/A</span>
                                        </div>
                                    </div>

                                    <div className='svg-span second-child'>
                                        <div className='child-svg-span'>
                                            <span className='investment-type'>{item.typeOfInvestment}</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="project-details-4-child">
                                    <p className='description'>Description</p>
                                    <span className='description-details'>
                                        {limitWords(removeHtmlTags(item.projectDescription))} <span className='read-more'>Read More</span>
                                    </span>
                                </div>

                                <div class="project-details-5-child">
                                    <div className="general-info-parent">
                                        <p>General Info</p>
                                        <div className="dropdown-parent-parent">
                                            <div className='dropdown-parent'>
                                                <div className="select">
                                                    <select name="format" id="format">
                                                        <option selected>Package 1</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='yields-parent'>
                                        <div className='yields-children'>
                                            <p className='value'>{item.assetMarketValueDiscount}%</p>
                                            <p className='desc' style={{ marginTop: "-8px" }}>Discount</p>
                                        </div>

                                        <div className='yields-children'>
                                            <p className='value'>{formatNumber(item.minimumPurchaseRequirement)} {item.minimumPurchaseRequirementUnit}</p>
                                            <p className='desc'>Minimum Purchase Limit</p>
                                        </div>

                                        <div className='yields-children'>
                                            <p className='value'>${formatNumber(item.minimumPurchaseRequirement * item.pricePerToken)} USD</p>
                                            <p className='desc'>Delivery Requirement</p>
                                        </div>

                                        {/* <div className='yields-children'>
                                            <p className='value'>180 Days</p>
                                            <p className='desc'>Product Ready ETA</p>
                                        </div> */}
                                    </div>
                                    
                                    <div className="general-info-parent incoterms-parent">
                                        <p>Incoterms</p>
                                        <div className="dropdown-parent-parent">
                                            <div className='dropdown-parent'>
                                                <p className='value'>{item.incoterms}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="project-details-6-child">
                                    <div className='svg-span'>
                                        <div className='child-svg-span'>
                                            <img className='logoImg' src={Logo} alt="Logo" />
                                            <span className='veri-pass-text'>
                                                Verification Passed <br />
                                                <span className='veri-pass-text-2'>This project has been legally & financially verified by Many Markup</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div className='project-details-7-child'>
                                    <Link className='Link' to={"/projectDetails"} state={{ projectId: item.id }}>
                                        <button>Research Project</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }
        </>
    )
}

export default PrimaryMarket