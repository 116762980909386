import React, { useEffect, useState } from 'react';
import './UserDashboard.css';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../../images/logo.png';
import styled, { css, keyframes } from 'styled-components';
import axios from 'axios';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import MUIDataTable from 'mui-datatables';

const UserDashboard = () => {
    const navigate = useNavigate();
    const [projects, setProjects] = useState([]);
    const [userID, setUserID] = useState();
    const [userDataDashboard, setUserDataDashboard] = useState([]);

    const [secondaryMarketData, setSecondaryMarketData] = useState([]);
    const [showMakeSecondary, setShowMakeSecondary] = useState(false);
    const [secondaryProjectId, setSecondaryProjectId] = useState();
    const [sellerAsk, setSellerAsk] = useState();
    const [isSecondary, setIsSecondary] = useState();
    
    const [secondaryProjectIdForTransfer, setSecondaryProjectIdForTransfer] = useState();
    const [showTransferOwnership, setShowTransferOwnership] = useState(false);
    const [transferOwnershipValues, setTransferOwnershipValues] = useState();

    const [currentActiveProjects, setCurrentActiveProjects] = useState();
    const [selectedTab, setSelectedTab] = useState('');
    const [currentIndex, setCurrentIndex] = useState(0);
    const [bidderData, setBidderData] = useState([]);

    useEffect(() => {
        const data = localStorage.getItem('data');

        if (data) {
            const parsedData = JSON.parse(localStorage.getItem("data"));
            const userData = parsedData.data[0]; // Access the first user object
            const userID = userData.id;
            setUserID(userID)
            // console.log("User Data: ", userData);
            // console.log(userData.id);
            
            const formData = new FormData();
            formData.append("userID", userID);
        
            axios.post('http://localhost:8081/get_the_owner_projects', formData).then((res) => {
                setProjects(res.data.data);
                setCurrentActiveProjects(res.data.data.length);
            });
        
            axios.post('http://localhost:8081/get_the_user_data', formData).then((res) => {
                setUserDataDashboard(res.data.data);
            });

            axios.post('http://localhost:8081/get_secondary_market_data', {userID: userID}).then((res) => {
                // Extracting values from each object in the array and replacing null or empty strings with 0
                const flattenedData = res.data.data.map(obj =>
                    Object.values(obj).map(value => (value === null || value === '' ? 0 : value))
            );
            setSecondaryMarketData(flattenedData);
            });
        } else {
            navigate('/');
        }
    }, []);

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % projects.length);
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + projects.length) % projects.length);
    };

    function limitWords(text) {
        // Input validation (handle non-string input gracefully)
        if (typeof text !== 'string') {
          return 'Invalid input: Please provide a string.';
        }
      
        // Check for empty or short strings
        if (text.trim().length <= 21) {
          return text; // No trimming needed
        }
      
        // Efficiently split into word array and handle multiple spaces
        const words = text.trim().replace(/\s+/g, ' ').split(' ');
      
        // Take words up to limit, handling edge cases and ensuring clean output
        const limitedWords = words.slice(0, 21).join(' ');
        const isTruncated = words.length > 12; // Flag for adding ellipsis
      
        return isTruncated ? `${limitedWords}...` : limitedWords;
    }

    function limitPrecent(value) {
        return `${value.toFixed(2)} %`;
    }

    const loadAnimation = (width) => keyframes`
        0% { width: 0%; }
        100% { width: ${width}%; }
    `;

    const StyledProgressValue = styled.div`
    animation: ${props => css`${loadAnimation(props.width)} 3s normal forwards`};
        border-radius: 100px;
        background: #f6d36a;
        box-shadow: 0px 0px 7px #f6d36a;
        height: 10px;
        width: 0;
    `;

    const formatNumber = (num) => {
        if (num >= 1e9) {
          return (num / 1e9).toFixed(2) + 'B';
        } else if (num >= 1e6) {
          return (num / 1e6).toFixed(2) + 'M';
        } else if (num >= 1e3) {
          return (num / 1e3).toFixed(2) + 'K';
        } else {
          return num.toString();
        }
    };

    const removeHtmlTags = (htmlString) => {
        const cleanString = htmlString.replace(/<[^>]*>/g, '');
        return cleanString;
    };
    const columns = ['Date', 'Project Name', 'Investor Name', 'Amount (USD)', 'Amount (Tokens)'];
    const data = [
        ['28/2/2024', '200,000 Tons Corn Export', 'U Min Thein Kyaw Zaya', '$1,000,000', '10000'],
        ['26/2/2024', 'Solar Energy Project in Mandalay', 'U David Hla Oo', '$431,000', '4,310'],
        ['24/2/2024', '200,000 Tons Corn Export', 'Daw Hla Shwe', '$71,000', '1294'],
        ['24/2/2024', '200,000 Tons Corn Export', 'U Sein Win', '$150,000', '2570'],
    ];
    
    const theme = createTheme({
        palette: {
            background: {
              paper: "#212121",
              default: "#212121",
            },
            mode: 'dark',
        },
        components: {
            MuiTableCell: {
              styleOverrides: {
                root: {
                  fontSize: '12px'
                },
              },
            },
        },
    });

    const MyDataTable = () => {
        const options = {
            rowsPerPage: 3,
            rowsPerPageOptions: [3],
            selectableRows: false,
            print: false,
            search: false,
            responsive: 'standard',
            customHeadRender: (columnMeta, handleToggleColumn) => {
                return (
                  <th key={columnMeta.index} style={{ whiteSpace: 'nowrap', minWidth: '100px' }}>
                    {columnMeta.label}
                  </th>
                );
            },
        };
      
        return (
          <MUIDataTable
            title={'Recent Investments'}
            data={data}
            columns={columns}
            options={options}
          />
        );
    };
    
    // const secondaryMarketData = [];
    const SecondaryMarketTable = () => {
        
        const secondaryMarketColumns = [
            {
                name: 'ID',
                options: {
                    display: false, // Hide the 'Date' column
                },
            },
            {
                name: 'Date',
                options: {
                    setCellProps: () => ({ style: { minWidth: '220px', textAlign: 'center' } })
                }
            },
            {
                name: 'Seller', // Assuming 'Seller' column contains the image URL
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const imageUrl = tableMeta.rowData[2]; // Assuming 'Seller' column contains the image URL
                        return <img src={`http://localhost:8081/${imageUrl.replace(/^public\/?|\/?$/g, "")}`} alt="Seller Image" className='seller-pic' />;
                    },
                    setCellProps: () => ({ style: { minWidth: '150px', textAlign: 'center' } }) 
                },
            },
            {
                name: 'Seller Name',
                options: {
                    setCellProps: () => ({ style: { minWidth: '180px', textAlign: 'center' } })
                }
            },
            {
                name: 'Primary Deal',
                options: {
                    setCellProps: () => ({ style: { minWidth: '190px', textAlign: 'center' } })
                }
            },
            {
                name: 'Sub Type',
                options: {
                    setCellProps: () => ({ style: { minWidth: '190px', textAlign: 'center' } })
                }
            },
            {
                name: 'Incoterms',
                options: {
                    setCellProps: () => ({ style: { minWidth: '180px', textAlign: 'center' } })
                }
            },
            {
                name: 'Region of Origin',
                options: {
                    setCellProps: () => ({ style: { minWidth: '150px', textAlign: 'center' } })
                }
            },
            {
                name: 'Underlying Value',
                options: {
                    customBodyRender: (value) => value.toLocaleString(), // Format the 'Underlying Value'
                    setCellProps: () => ({ style: { minWidth: '220px', textAlign: 'center' } })
                },
            },
            {
                name: 'Category',
                options: {
                    customBodyRender: (value) => (
                        <a href={value} target='_blank' className='tag-text'>{value}</a>
                    ),
                    setCellProps: () => ({ style: { minWidth: '200px', textAlign: 'center' } })
                }
            },
            {
                name: 'Type',
                options: {
                    customBodyRender: (value) => (
                        <a href={value} target='_blank' className='tag-text'>{value}</a>
                    ),
                    setCellProps: () => ({ style: { minWidth: '200px', textAlign: 'center' } })
                }
            },
            {
                name: 'Seller Ask',
                options: {
                    customBodyRender: (value) => value.toLocaleString(), // Format the 'Seller Ask'
                    setCellProps: () => ({ style: { minWidth: '200px', textAlign: 'center' } })
                },
            },
            {
                name: 'Highest Bids',
                options: {
                    customBodyRender: (value) => {
                        let highestBidValue = 0;
                        try {
                            const bidData = JSON.parse(value);
                            if (Array.isArray(bidData)) {
                                bidData.forEach(bid => {
                                    const bidValue = parseInt(bid[2]);
                                    if (!isNaN(bidValue)) {
                                        if (bidValue > highestBidValue) {
                                            highestBidValue = bidValue;
                                        }
                                    } else {
                                        console.error("Invalid bid value:", bid[2]);
                                    }
                                });
                            } else {
                                console.error("Value is not an array:", value);
                            }
                        } catch (error) {
                            console.error("Error parsing JSON:", error);
                        }
                        return highestBidValue.toLocaleString();
                    },
                    setCellProps: () => ({ style: { minWidth: '210px', textAlign: 'center' } })
                },
            },
            {
                name: 'Make Secondary',
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const passDataToMakeSecondary = () => {
                            const projectId = tableMeta.rowData[0];
                            setSecondaryProjectId(projectId);
                            showMakeSecondaryModal();
                        }
        
                        // console.log(tableMeta.rowData);
                        const is_secondary = tableMeta.rowData[13];
                        setIsSecondary(is_secondary);
        
                        return is_secondary <= 1 ? <button className='make-secondary-button' onClick={passDataToMakeSecondary}>Make Secondary</button>
                        : <button className='make-secondary-button make-secondary-button2' disabled>Already Issued</button>
                    },
                    setCellProps: () => ({ style: { minWidth: '210px', textAlign: 'center' } })
                },
            },
            {
                name: 'Transfer Ownership',
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const transferOwnership = () => {
                            const projectId = tableMeta.rowData[0];
                            showTransferOwnershipModal();
                            handleTransferOwnership(projectId);
                            setBidderData(JSON.parse(tableMeta.rowData[12]));
                        }
        
                        return (
                            <>
                                <div className="parent-parent-invest-failed" style={{ display: showTransferOwnership ? 'inline-block' : 'none' }}>
                                    <div className="parent-invest-failed">
                                        <div className='invest-data'>
                                            <p className="title">Transfer Ownership</p>
                                            <select onChange={handleChangeTransferOwnership} name="investmentCategory" className="transfer-ownership-select">
                                                
                                                {   
                                                    bidderData.length > 0 ? ( bidderData.map((value) => {
                                                        return (
                                                            <>
                                                                <option disabled selected>Choose Who To Transfer Ownership</option>
                                                                <option>{`${value[1]} - $${parseInt(value[2]).toLocaleString()}USD`}</option>
                                                            </>
                                                        )
                                                    })
                                                    ) : (
                                                        <option disabled selected>No Bidders Yet</option>
                                                    )
                                                }
                                            </select>
                                            <div className='two-buttons-invest-modal'>
                                                <button className='closeInvestButton' onClick={transferOwnership}>Transfer Ownership</button>
                                                <button className='closeInvestButton cancelInvestButton' onClick={closeTransferOwnership}>CANCEL</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button className='make-secondary-button' onClick={transferOwnership}>Transfer</button>
                            </>
                        )
                    },
                    setCellProps: () => ({ style: { minWidth: '210px' } })
                },
            }
        ];

        const options = {
            rowsPerPage: 5,
            rowsPerPageOptions: [5],
            selectableRows: false,
            print: false,
            search: false,
            scroll: false,
            download: false,
            responsive: 'scrollMaxHeight',
            customHeadRender: (columnMeta, handleToggleColumn) => {
            return (
                <th key={columnMeta.index} style={{ whiteSpace: 'nowrap', minWidth: '100px' }}>
                    {columnMeta.label}
                </th>
                );
            },
        };
        const tableContainerStyle  = {
            width: '100%',
        };

        const getMuiTheme = () => createTheme({
            palette: {
                background: {
                paper: "#212121",
                default: "#212121",
                },
                mode: 'dark',
            },
            components: {
                MuiTableCell: {
                styleOverrides: {
                    root: {
                        fontSize: '12px',
                        textAlign: 'center',
                    },
                },
                },
            },
        });
    
        // const realSecondaryData = secondaryMarketData.map(row => row.slice(0));
        return (
            <div style={tableContainerStyle}>
                <ThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        title={'Secondary Market'}
                        data={secondaryMarketData}
                        columns={secondaryMarketColumns}
                        options={options}
                    />
            </ThemeProvider>
            </div>
        );
    };

    const showMakeSecondaryModal = () => {
        setShowMakeSecondary(true);
    }

    const closeMakeSecondary = () => {
        setShowMakeSecondary(false);
    }

    const handleSellerAskChange = (e) => {
        const sellerAsk = e.target.value;
        // console.log(sellerAsk);
        setSellerAsk(sellerAsk);
    }

    const makeSecondary = () => {
        // console.log(secondaryProjectId);
        const requestData = {
            secondaryProjectId: secondaryProjectId,
            sellerAsk: sellerAsk,
        }
        axios.post('http://localhost:8081/make_secondary', requestData).then((res) => {
            setShowMakeSecondary(false);
            window.location.reload(); // This line reloads the page
        });
    }

    const showTransferOwnershipModal = () => {
        setShowTransferOwnership(true);
    }

    const closeTransferOwnership = () => {
        setShowTransferOwnership(false);
    }

    const transferOwnership = () => {
        const requestData = {
            secondaryProjectId: secondaryProjectIdForTransfer,
        }
        axios.post('http://localhost:8081/transfer_ownership', requestData).then((res) => {
            
        });
    }

    const handleChangeTransferOwnership = (e) => {
        const transferValue = e.target.value;
        setTransferOwnershipValues(transferValue);
    }

    const handleTransferOwnership = (projectId) => {
        setSecondaryProjectIdForTransfer(projectId);
    };

    useEffect(() => {
        // Set the initial selected tab to tab-1
        setSelectedTab('db-tab-2');
    }, []);

    const handleTabChange = (e) => {
        setSelectedTab(e.target.id);
    };

    return (
        <>

        {/* Make Secondary Modal */}
        <div className="parent-parent-invest-failed" style={{ display: showMakeSecondary ? 'inline-block' : 'none' }}>
            <div className="parent-invest-failed">
                <div className='invest-data'>
                    <p className="title">Make Secondary</p>
                    <input onChange={handleSellerAskChange} 
                        className='first-input' type="text" placeholder='How much you ask for this project to secondary market' />

                        <div className='two-buttons-invest-modal'>
                            <button className='closeInvestButton' onClick={makeSecondary}>Got It</button>
                            <button className='closeInvestButton cancelInvestButton' onClick={closeMakeSecondary}>CANCEL</button>
                        </div>
                </div>
            </div>
        </div>

        <div className='db-market-parent-wrapper'>
            <div class="db-market-wrapper">
                <div class="db-market-container">
                    <div class="db-market-card db-market-tabs">
                        <input id="db-tab-1" type="radio" class="db-tab db-tab-selector" name="tab"
                            checked={selectedTab === 'db-tab-1'}  onChange={handleTabChange} />
                        <label for="db-tab-1" class="db-tab db-tab-primary">Overview</label>

                        <input id="db-tab-2" type="radio" class="db-tab db-tab-selector" name="tab"
                            checked={selectedTab === 'db-tab-2'}  onChange={handleTabChange} />
                        <label for="db-tab-2" class="db-tab tab-success">Assets</label>

                        <div class="db-tabsShadow"></div>
                        <div class="db-glider"></div>

                        <section class="db-content">
                            <div class="item" id="db-content-1">
                                <div className='db-first-row'>
                                    <div class="first-column">
                                    {
                                        userDataDashboard.map((user) => (
                                        <div className='fist-col-child'>
                                            <div className='user-image-name'>
                                                <img className="user-image" src={`http://localhost:8081/${user.user_image.replace(/^public\/?|\/?$/g, "")}`} />
                                                <p className='name-of-the-user'>User's Name</p>
                                                <div className='user-verified'>
                                                    <span>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style={{verticalAlign: "middle", marginRight: "4px", fill: "rgba(57, 211, 106, 1)", transform: ";msFilter:"}}><path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"></path><path d="M9.999 13.587 7.7 11.292l-1.412 1.416 3.713 3.705 6.706-6.706-1.414-1.414z"></path></svg>
                                                             Verified
                                                    </span>

                                                    <span>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style={{verticalAlign: "middle", marginRight: "4px", fill: "rgba(30, 241, 228, 1)", transform: ";msFilter:"}}><path d="M20.995 6.9a.998.998 0 0 0-.548-.795l-8-4a1 1 0 0 0-.895 0l-8 4a1.002 1.002 0 0 0-.547.795c-.011.107-.961 10.767 8.589 15.014a.987.987 0 0 0 .812 0c9.55-4.247 8.6-14.906 8.589-15.014zM12 19.897C5.231 16.625 4.911 9.642 4.966 7.635L12 4.118l7.029 3.515c.037 1.989-.328 9.018-7.029 12.264z"></path><path d="m11 12.586-2.293-2.293-1.414 1.414L11 15.414l5.707-5.707-1.414-1.414z"></path></svg>
                                                        Regular Acc
                                                    </span>
                                                 </div>
                                            </div>

                                            <div className='current-active-project-parent'>
                                                <h3>5</h3>
                                                <p>Current Active Projects</p>
                                            </div>

                                            <div class="db-details-6-child">
                                                <div className='svg-span'>
                                                    <div className='child-svg-span'>
                                                        <img className='logoImg' src={Logo} alt="Logo" />
                                                        <span className='veri-pass-text'>
                                                            KYC User <br />
                                                        <span className='veri-pass-text-2'>This person has been submitted documentation to ManyMarkup & verified KYC via project ownership of documentation.</span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        ))
                                    }
                                    </div>
                                    <div class="second-column">
                                        <div className='overview-middle-side-parent1'>
                                            <p className='overview-metric-title'>Overview Metrics</p>
                                            <div className='overview-middle-side-child1'>
                                                <div className='overview-middle-side-grandchild' style={{ marginTop: "10px" }}>
                                                    <p className='data'>N/A</p>
                                                    <p className='kpi'>Followers</p>
                                                </div>

                                                <div className='overview-middle-side-grandchild' style={{ marginTop: "10px" }}>
                                                    <p className='data'>N/A</p>
                                                    <p className='kpi'>Followings</p>
                                                </div>

                                                <div className='overview-middle-side-grandchild'>
                                                    <p className='data'>N/A</p>
                                                    <p className='kpi'>Total Engaged Projects</p>
                                                </div>

                                                <div className='overview-middle-side-grandchild'>
                                                    <p className='data'>N/A</p>
                                                    <p className='kpi'>Lifetime Raised</p>
                                                </div>

                                                <div className='overview-middle-side-grandchild'>
                                                    <p className='data'>N/A</p>
                                                    <p className='kpi'>Repayment Rate</p>
                                                </div>

                                                <div className='overview-middle-side-grandchild'>
                                                    <p className='data'>N/A</p>
                                                    <p className='kpi'>Extension Rate</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="third-column">
                                        <div className="pagination-container">
                                            <button id="prevBtn" className="arrow left" onClick={handlePrev}>&#9664;</button>
                                            {
                                                projects.length > 0 && (
                                                    <div className="parent-standard-card">
                                                        <div class="parent-pm-center">
                                                            <div class="pm-center">
                                                                <div class="article-card">
                                                                    <div class="content investment-type">
                                                                        <p class="title investment-type-text">{projects[currentIndex].investmentCategory}</p>
                                                                    </div>
                                                                    <div class="content">
                                                                        <p class="title">{projects[currentIndex].projectName}</p>
                                                                        <p class="date">Proposed By <b>{projects[currentIndex].projectOwner}</b></p>
                                                                    </div>
                                                                    <img src={`http://localhost:8081/${projects[currentIndex].projectPhoto.replace(/^public\/?|\/?$/g, "")}`} alt="article-cover" />
                                                                </div>
                                                            </div>
                                                            <div className="parent-project-details">
                                                                {/* This is the first child */}
                                                                <div class="project-details-5-child project-detail-1-child">
                                                                    <div className='yields-parent'>
                                                                        <div className='yields-children first-child'>
                                                                            <p className='desc'>Raised So Far</p>
                                                                            <p className='value'>${formatNumber(projects[currentIndex].raised_so_far)}
                                                                                <span> {limitPrecent(((projects[currentIndex].raised_so_far / (projects[currentIndex].maxTokens * projects[currentIndex].pricePerToken)))*100)}</span>
                                                                            </p>
                                                                            
                                                                        </div>
                                                                        <div className='yields-children last-child'>
                                                                            <p className='desc'>Target</p>
                                                                            <p className='value'>${formatNumber(projects[currentIndex].maxTokens * projects[currentIndex].pricePerToken)}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className='project-details-2-child'>
                                                                    <div class="progress">
                                                                        <StyledProgressValue width={((projects[currentIndex].raised_so_far / (projects[currentIndex].maxTokens * projects[currentIndex].pricePerToken)))*100}></StyledProgressValue>
                                                                    </div>
                                                                </div>

                                                                <div class="project-details-3-child">
                                                                    <div className='svg-span'>
                                                                        <div className='child-svg-span'>
                                                                            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="6" r="4" stroke="#ADADAD" stroke-width="1.5"></circle> <path d="M19.9975 18C20 17.8358 20 17.669 20 17.5C20 15.0147 16.4183 13 12 13C7.58172 13 4 15.0147 4 17.5C4 19.9853 4 22 12 22C14.231 22 15.8398 21.8433 17 21.5634" stroke="#ADADAD" stroke-width="1.5" stroke-linecap="round"></path> </g></svg>
                                                                            <span>-</span>
                                                                        </div>
                                                                    </div>

                                                                    <div className='svg-span second-child'>
                                                                        <div className='child-svg-span'>
                                                                            <svg fill="#ADADAD" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-7.59V4h2v5.59l3.95 3.95-1.41 1.41L9 10.41z"></path></g></svg>
                                                                            <span>N/A</span>
                                                                        </div>
                                                                    </div>

                                                                    <div className='svg-span second-child'>
                                                                        <div className='child-svg-span'>
                                                                            <span className='investment-type'>{projects[currentIndex].typeOfInvestment}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="project-details-4-child">
                                                                    <p className='description'>Description</p>
                                                                    <span className='description-details'>
                                                                        {limitWords(removeHtmlTags(projects[currentIndex].projectDescription))}... <span className='read-more'>Read More</span>
                                                                    </span>
                                                                </div>

                                                                <div class="project-details-5-child">
                                                                    <div className="general-info-parent">
                                                                        <p>General Info</p>
                                                                        <div className="dropdown-parent-parent">
                                                                            <div className='dropdown-parent'>
                                                                                <div className="select">
                                                                                    <select name="format" id="format">
                                                                                        <option selected>Package 1</option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='yields-parent'>
                                                                        <div className='yields-children'>
                                                                            <p className='value'>{projects[currentIndex].assetMarketValueDiscount}%</p>
                                                                            <p className='desc'>Discount</p>
                                                                        </div>

                                                                        <div className='yields-children'>
                                                                            <p className='value'>${formatNumber(projects[currentIndex].minimumPurchaseRequirement)} USD</p>
                                                                            <p className='desc'>Minimum Purchase Limit</p>
                                                                        </div>

                                                                        <div className='yields-children'>
                                                                            <p className='value'>${formatNumber(projects[currentIndex].minimumPurchaseRequirement * projects[currentIndex].pricePerToken)} USD</p>
                                                                            <p className='desc'>Delivery Requirement</p>
                                                                        </div>

                                                                        {/* <div className='yields-children'>
                                                                            <p className='value'>180 Days</p>
                                                                            <p className='desc'>Product Ready ETA</p>
                                                                        </div> */}
                                                                    </div>
                                                                </div>

                                                                <div class="project-details-6-child">
                                                                    <div className='svg-span'>
                                                                        <div className='child-svg-span'>
                                                                            <img className='logoImg' src={Logo} alt="Logo" />
                                                                            <span className='veri-pass-text'>
                                                                                Verification Passed <br />
                                                                                <span className='veri-pass-text-2'>This project has been legally & financially verified by Many Markup</span>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className='project-details-7-child'>
                                                                    <Link className='Link' to={"/projectDetails"} state={{ projectId: projects[currentIndex].id }}>
                                                                        <button>Research Project</button>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            <button id="nextBtn" className="arrow right" onClick={handleNext}>&#9654;</button>
                                        </div>
                                    </div>
                                </div>

                                <div className='recent-transaction-table'>
                                    <ThemeProvider theme={theme}>
                                        <MyDataTable />
                                    </ThemeProvider >
                                </div>
                            </div>

                            <div class="item" id="db-content-2">
                                <div className='secondary-market-table'>
                                    <ThemeProvider theme={theme}>
                                        <SecondaryMarketTable />
                                    </ThemeProvider >
                                </div>
                            </div>
                        </section>
                        
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default UserDashboard