import React, { useEffect, useState } from 'react';
import './TokenizeForm.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import checked from '../../images/checked.png';
import unchecked from '../../images/unchecked.png';

const TokenizeForm = () => {
    const navigate = useNavigate();

    const [values, setValues] = useState({
        projectName: '',
        projectSubType: '',
        typeOfInvestment: '',
        investmentCategory: '',
        incoterms: '',
        proteinContent: '',
        moistureContent: '',
        foreignMat: '',
        damagedKernels: '',
        assetMarketValue: '',
        assetMarketValueDiscount: '',
        forwardSellingNumber: '',
        forwardSellingUnitType: '',
        maxTokens: '',
        pricePerToken: '',
        projectPhoto: '',
        projectGalleryPhotos: [],
        projectCertificatePhotos: [],
        docs: [],
        minimumPurchaseRequirement: '',
        minimumPurchaseRequirementUnit: '',
        projectDescriptionTitle: '',
        projectDescription: '',
        sellerStatus: '',
        liableAccount: '',
    });
    const [file, setFile] = useState();
    const [files, setFiles] = useState([]);
    const [certificates, setCertificates] = useState([]);
    const [docs, setDocs] = useState([]);
    const [projectOwner, setProjectOwner] = useState('');
    const [projectOwnerID, setProjectOwnerID] = useState('');
    const [tokenizeSuccess, setTokenizeSuccess] = useState(false);
    const [tokenizeFailed, setTokenizeFailed] = useState(false);
    const [descText, setDescText] = useState('');

    const [isParameterChecked, setIsParameterChecked] = useState(false);

    const handleFile= (e) => {
        setFile(e.target.files[0]);
    }

    const handleFiles = (event) => {
        setFiles(event.target.files);
    };

    const handleCertificates = (event) => {
        setCertificates(event.target.files);
    };

    const handleDocs = (event) => {
        setDocs(event.target.files);
    }

    const handleChange = (e) => {
        setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }
    // console.log(values);

    useEffect(() => {
        const data = localStorage.getItem('data');

        if (data) {
            const parsedData = JSON.parse(localStorage.getItem("data"));
            const userData = parsedData.data[0]; // Access the first user object

            setProjectOwnerID(userData.id)
            setProjectOwner(userData.name);
        } else {
            navigate('/');
        }
    })

    const submitProposal = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('projectPhoto', file);

        Array.from(files).forEach((file) => {
            formData.append('projectGalleryPhotos', file);
        });

        Array.from(certificates).forEach((certificate) => {
            formData.append('projectCertificatePhotos', certificate);
        });

        Array.from(docs).forEach((doc) => {
            formData.append('docs', doc);
        });

        formData.append('projectOwnerID', projectOwnerID);
        formData.append('projectOwner', projectOwner);
        formData.append('projectName', values.projectName);
        formData.append('projectSubType', values.projectSubType);
        formData.append('typeOfInvestment', values.typeOfInvestment);
        formData.append('investmentCategory', values.investmentCategory);
        formData.append('incoterms', values.incoterms);
        formData.append('proteinContent', values.proteinContent);
        formData.append('moistureContent', values.moiContent);
        formData.append('foreignMat', values.foreignMat);
        formData.append('damagedKernels', values.damagedKernels);
        formData.append('assetMarketValue', values.assetMarketValue);
        formData.append('assetMarketValueDiscount', values.assetMarketValueDiscount);
        formData.append('forwardSellingNumber', values.forwardSellingNumber);
        formData.append('forwardSellingUnitType', values.forwardSellingUnitType);
        formData.append('maxTokens', values.maxTokens);
        formData.append('pricePerToken', values.pricePerToken);
        formData.append('minimumPurchaseRequirement', values.minimumPurchaseRequirement);
        formData.append('minimumPurchaseRequirementUnit', values.minimumPurchaseRequirementUnit);
        formData.append('projectDescriptionTitle', values.projectDescriptionTitle);
        // formData.append('projectDescription', values.projectDescription);
        formData.append('projectDescription', descText);
        formData.append('sellerStatus', values.sellerStatus);
        formData.append('liableAccount', values.liableAccount);

        try {
            axios.post("http://localhost:8081/upload_tokenize_project", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            })
            .then((res) => {
                if(res.data["tokenizedFormUploadStatus"]) {
                    setTokenizeSuccess(true);
                } else {
                    setTokenizeFailed(true);
                }
                
            });

        } catch (err) {
            // console.log(err);
        }
    }

    const closeTokenizeSuccessModal = () => {
        setTokenizeSuccess(false);
        navigate('/');
    }

    const closeTokenizeFailedModal = () => {
        setTokenizeFailed(false);
    }

    const modules = {
        toolbar: [
          [{ header: [1, 2, false] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
          ['emoji'],
        ],
    };

    const formats = [
        'header', 'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent', 'emoji'
    ];

    const handleChangeDesc = (value) => {
        setDescText(value);
        // console.log(descText);
    };

    const handleCheckboxChange = (e) => {
        setIsParameterChecked(e.target.checked);
    };

    return (
        <>
        {/* Tokenize Success Modal */}
        <div className="parent-parent-login-success" style={{ display: tokenizeSuccess ? 'inline-block' : 'none' }}>
            <div className="parent-login-success">
                <div className="svg-parent">
                  <svg fill="#44ff02" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <title>checkmark</title> <path d="M16 3c-7.18 0-13 5.82-13 13s5.82 13 13 13 13-5.82 13-13-5.82-13-13-13zM23.258 12.307l-9.486 9.485c-0.238 0.237-0.623 0.237-0.861 0l-0.191-0.191-0.001 0.001-5.219-5.256c-0.238-0.238-0.238-0.624 0-0.862l1.294-1.293c0.238-0.238 0.624-0.238 0.862 0l3.689 3.716 7.756-7.756c0.238-0.238 0.624-0.238 0.862 0l1.294 1.294c0.239 0.237 0.239 0.623 0.001 0.862z"></path> </g></svg>
                </div>
                <p className="title">Congratulations!</p>
                <p className="text">Your tokenization procedure has been successfully uploaded to ManyMarket system base.</p>
                <button className='button' onClick={closeTokenizeSuccessModal}>Got It</button>
            </div>
        </div>

        {/* Tokenize Failed Modal */}
        <div className="parent-parent-login-success" style={{ display: tokenizeFailed ? 'inline-block' : 'none' }}>
            <div className="parent-login-success">
                <div className="svg-parent">
                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#f00"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M16 8L8 16M12 12L16 16M8 8L10 10M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#f00" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                </div>
                <p className="title">Tokenization Failed!</p>
                <p className="text">Sorry your tokenization process has been failed. Please try to upload your information again.</p>
                <button className='button' onClick={closeTokenizeFailedModal}>Got It</button>
            </div>
        </div>

        <div className='parent-tokenizedForm'>
            <div className='tokenizedForm'>
                <h1>Tokenization Form</h1>
                <div className='input-parents'>
                    <div className="form-input-divs">
                        <span>Project Name</span>
                        <input onChange={handleChange} name='projectName' type="text" placeholder='Type your project name (what will you offer)' />
                    </div>

                    <div className="form-input-divs form-input-divs2">
                        <span>Product Sub Type</span>
                        <input onChange={handleChange} name='projectSubType' type="text" placeholder='Sub type of your product (Rice - Basmati)' />
                    </div>

                    <div className="form-input-divs form-input-divs2">
                        <span>Type of Investment</span>
                        <select onChange={handleChange} name="typeOfInvestment" className="shorter-select">
                            <option disabled selected>Choose Investment Type</option>
                            <option value="NYCA">NYCA (No Yield Claimable Assets)</option>
                            <option value="TPHA">TPHA (Time Passive Holding Assets)</option>
                            <option value="HYRA">HYRA (High Yield Return Assets)</option>
                            <option value="SYA">SYA (Safe Yield Assets)</option>
                            <option value="SYC">SYC (Special Yield & Claimable)</option>
                        </select>
                    </div>

                    <div className="form-input-divs form-input-divs2 shorter-select">
                        <span>Investment Category</span>
                        <select onChange={handleChange} name="investmentCategory" className="shorter-select">
                            <option disabled selected>Choose Investment Category</option>
                            <option value="Commodity(Ed)">Commodity Market (Edible)</option>
                            <option value="Commodity(Non-Ed)">Commodity Market (Non-Edible)</option>
                            <option value="Mining Products">Mining Products Market</option>
                            <option value="Bond Debt">Bond & Debt Market</option>
                            <option value="Artwork">Artwork Market</option>
                            <option value="Personal Collection">Personal Collection Market</option>
                            <option value="Vehicle">Vehicle Market</option>
                            <option value="Personal Jewellery">Personal Jewellery Market</option>
                            <option value="Real Estate">Real Estate Market</option>
                            <option value="Project Fund Raisers">Project & Fund Raisers</option>
                            <option value="ESG Funds">ESG Funds</option>
                            <option value="Energy">Energy</option>
                            <option value="Institutions">Institutions</option>
                            <option value="Sports Clubs">Sports & Clubs</option>
                            <option value="Trade Finance">Trade Finance</option>
                        </select>
                    </div>

                    <div className="form-input-divs form-input-divs2">
                        <span>Type of Incoterms</span>
                        <select onChange={handleChange} name="incoterms" className="shorter-select">
                            <option disabled selected>Choose Types of Incoterms</option>
                            <option value="FOB">FOB (Free on Board)</option>
                            <option value="CFR">CFR (Coast and Freight)</option>
                            <option value="CIF">CIF (Cost, Insurance and Freight)</option>
                            <option value="EXW">EXW (Ex Works)</option>
                            <option value="DAP">DAP (Delivered at Place)</option>
                            <option value="DDP">DDP (Delivered Duty Paid)</option>
                        </select>
                    </div>
                    
                    <div className="form-input-divs form-input-divs2 parameter-div">
                        <label htmlFor='parameter-checked' className='parametersText'>Parameters <img className='parameter-check-icon' src={!isParameterChecked ? unchecked : checked} /> </label>
                        <input id='parameter-checked' type="checkbox" onChange={handleCheckboxChange} />

                        {isParameterChecked && (
                            <div className='market-value-div'>
                                <div className='column'>
                                    <input onChange={handleChange} name='proteinContent' type="text" placeholder='Protein Content %' />
                                </div>
                                <div className='column'>
                                    <input onChange={handleChange} name='moiContent' type="text" placeholder='Moisture Content %' />
                                </div>

                                <div className='column col2'>
                                    <input onChange={handleChange} name='foreignMat' type="text" placeholder='Foreign Materials or Contaminants %' />
                                </div>

                                <div className='column col2'>
                                    <input onChange={handleChange} name='damagedKernels' type="text" placeholder='Damaged Kernels %' />
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="form-input-divs form-input-divs2 form-input-divs3">
                        <span>Your Proposed Asset Market Value</span>
                        <div className='market-value-div'>
                            <input onChange={handleChange} name='assetMarketValue' type="text" placeholder='Market value of your asset' />
                            <span>USD</span>
                        </div>
                        <input onChange={handleChange} name='assetMarketValueDiscount' type="text" placeholder='Discounted value (optional)' />
                    </div>

                    <div className="form-input-divs form-input-divs2 form-input-divs3">
                        <span>Your Desire Forward Selling Reserve</span>
                        <div className='market-value-div'>
                            <input onChange={handleChange} name='forwardSellingNumber' type="text" placeholder='Number of units' />
                        </div>
                        <input onChange={handleChange} name='forwardSellingUnitType' type="text" placeholder='Unit (i.e. ton, carat, rooms, barrels)' />
                    </div>

                    <div className="form-input-divs form-input-divs2 form-input-divs3">
                        <span>Max Tokens</span>
                        <div className='market-value-div'>
                            <input onChange={handleChange} name='maxTokens' type="text" placeholder='Enter your issue tokens' />
                            <span>t</span>
                        </div>
                    </div>

                    <div className="form-input-divs form-input-divs2 form-input-divs3">
                        <span>Price / t</span>
                        <div className='market-value-div'>
                            <input onChange={handleChange} name='pricePerToken' type="text" placeholder='Price per token' />
                            <span>/ t</span>
                        </div>
                    </div>

                    <div className="form-input-divs form-input-divs2">
                        <span>Project Cover Photo (Single)</span>
                        <input onChange={handleFile} name='projectPhoto' type="file" accept="image/*" multiple="false" />
                    </div>

                    <div className="form-input-divs form-input-divs2">
                        <span>Photos For Project (Gallery) <br/> Max 10 Photos</span>
                        <input onChange={handleFiles} name='projectGalleryPhotos' type="file" accept="image/*" multiple />
                    </div>

                    <div className="form-input-divs form-input-divs2">
                        <span>Project Certificates Photos<br/> Max 10 Photos</span>
                        <input onChange={handleCertificates} name='projectCertificatePhotos' type="file" accept="image/*" multiple />
                    </div>

                    <div className="form-input-divs form-input-divs2">
                        <span>Documents (Word, Excel, PPT)<br/> Max 5 Files</span>
                        <input onChange={handleDocs} name='docs' type="file" accept=".doc,.docx,.xls,.xlsx,.ppt,.pptx" multiple />
                    </div>

                    <div className="form-input-divs form-input-divs2 form-input-divs3">
                        <span>Minimum Purchase Requirement</span>
                        <div className='market-value-div'>
                            <input onChange={handleChange} name='minimumPurchaseRequirement' className='minimumPurchaseRequirement' type="text" placeholder='Minimum requirement to invest on your current package token distribution' />
                        </div>
                        <select onChange={handleChange} name="minimumPurchaseRequirementUnit">
                            <option selected disabled>Choose Unit Type</option>
                            <option value="Tokens">Tokens</option>
                            <option value="Percent">Percent</option>
                        </select>
                    </div>
                    
                    <div className="form-input-divs">
                        <span>Description Title</span>
                        <input onChange={handleChange} name='projectDescriptionTitle' type="text" placeholder='Title of your project description' />
                    </div>

                    <div className="form-input-divs form-input-divs2">
                        <span>Description</span>
                        <ReactQuill
                            value={descText}
                            onChange={handleChangeDesc}
                            modules={modules}
                            formats={formats}
                            placeholder="Type something..."
                            className='descriptionBox'
                        />
                        {/* <textarea onChange={handleChange} name='projectDescription' className='description' rows="6" placeholder='Tell something about your project to potential investors' /> */}
                    </div>

                    <div className="form-input-divs form-input-divs2 form-input-divs3">
                        <span>Seller Status</span>
                        <div className='market-value-div'>
                            <select onChange={handleChange} name="sellerStatus">
                                <option disabled selected>Choose Seller Status</option>
                                <option value="Owner">Owner</option>
                                <option value="Third Party">Third Party</option>
                                <option value="Behalf">Behalf</option>
                            </select>
                        </div>
                    </div>

                    <div className="form-input-divs form-input-divs2 form-input-divs3">
                        <span>Liable Account</span>
                        <div className='market-value-div'>
                            <input onChange={handleChange} name='liableAccount' className='liableAccount' type="text" placeholder='Account id' />
                        </div>
                    </div>

                    <button onClick={submitProposal} className='submit-proposal'>Submit Proposal</button>
                </div>
            </div>
        </div>
        </>
    )
}

export default TokenizeForm