import React, { useState } from 'react';
import axios from 'axios';
import './LoginRegister.css';
import { useNavigate } from 'react-router-dom';
import Party from '../../images/party.png';

const LoginRegister = () => {

    const navigate = useNavigate();

    const [file, setFile] = useState();
    const handleFile= (e) => {
        setFile(e.target.files[0]);
    }

    const [values, setValues] = useState({
        logname: '',
        logemail: '',
        logpass: ''
    });

    const [loginValues, setLoginValues] = useState({
        loginemail: '',
        loginpass: ''
    });

    const [emailUsed, setEmailUsed] = useState(false);
    const [registerSuccess, setRegisterSuccess] = useState(false);
    const [registerFailed, setRegisterFailed] = useState(false);
    const [loginSuccess, setLoginSuccess] = useState(false);
    const [loginFailed, setLoginFailed] = useState(false);

    const handleChange = (e) => {
        setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const handleClick = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('logimage', file);
        formData.append('logname', values.logname);
        formData.append('logemail', values.logemail);
        formData.append('logpass', values.logpass);

        axios.post(`${process.env.REACT_APP_API_URL}/auth`, formData)
        .then((res) => {
            if(res.data.emailAlreadyUsed) {
                setEmailUsed(true);
                navigate("/auth");
            } else if(res.data.registerSuccess) {
                setRegisterSuccess(true);
            } else {
                setRegisterFailed(true);
            }
        })
        .catch(err => console.log(err));
    };

    const handleChangeLogin = (e) => {
        setLoginValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const handleLogin = (e) => {
        e.preventDefault();
        axios.post(`${process.env.REACT_APP_API_URL}/login`, loginValues)
            .then((res) => {
                if(res.data.data.length > 0) {
                    if(res.data.loginSuccess) {
                        setLoginSuccess(true);
                    } else {
                        setLoginFailed(true);
                    }
                    localStorage.setItem("token", res.data.token);
                    localStorage.setItem("data", JSON.stringify(res.data));
                } else {
                    setLoginFailed(true);
                }
            })
            .catch(err => console.log(err));
    };

    const userAuthenticated = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/isUserAuth`, {
            headers: {
                "x-access-token": localStorage.getItem("token"),
            }
        }).then((res) => {
            // handle response
        }).catch(err => console.log(err));
    };
    
    userAuthenticated();

    const closeEmailAlreadyUsedModal = () => {
        setEmailUsed(false);
    };

    const closeRegisterSuccessModal = () => {
        setRegisterSuccess(false);
    };

    const closeRegisterFailedModal = () => {
        setRegisterFailed(false);
    };

    const closeLoginSuccessModal = () => {
        setLoginSuccess(false);
        navigate('/');
    };

    const closeLoginFailedModal = () => {
        setLoginFailed(false);
    };

    return (
        <>  
        {/* Email Already Used Modal */}
        <div className="parent-parent-email-already-used active" style={{ display: emailUsed ? 'inline-block' : 'none' }}>
            <div className="parent-email-already-used">
                <div className="svg-parent">
                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#f00"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M16 8L8 16M12 12L16 16M8 8L10 10M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#f00" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                </div>
                <p className="title">Email Already Used!</p>
                <p className="text">Please use another email and try again.</p>
                <button className='button' onClick={closeEmailAlreadyUsedModal}>Got It</button>
            </div>
        </div>

        {/* Register Success Modal */}
        <div className="parent-parent-register-success" style={{ display: registerSuccess ? 'inline-block' : 'none' }}>
            <div className="parent-register-success">
                <div className="svg-parent">
                  <img src={Party} alt="" />
                </div>
                <p className="title">Congratulations!</p>
                <p className="text">You have successfully registered an account at ManyMarket. Please Login!</p>
                <button className='button' onClick={closeRegisterSuccessModal}>Got It</button>
            </div>
        </div>

        {/* Register Failed Modal */}
        <div className="parent-parent-register-failed" style={{ display: registerFailed ? 'inline-block' : 'none' }}>
            <div className="parent-register-failed">
                <div className="svg-parent">
                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#f00"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M16 8L8 16M12 12L16 16M8 8L10 10M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#f00" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                </div>
                <p className="title">Register Failed!</p>
                <p className="text">User account register has been failed. Please check your credentials carefully.</p>
                <button className='button' onClick={closeRegisterFailedModal}>Got It</button>
            </div>
        </div>

        {/* Login Success Modal */}
        <div className="parent-parent-login-success" style={{ display: loginSuccess ? 'inline-block' : 'none' }}>
            <div className="parent-login-success">
                <div className="svg-parent">
                  <svg fill="#44ff02" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <title>checkmark</title> <path d="M16 3c-7.18 0-13 5.82-13 13s5.82 13 13 13 13-5.82 13-13-5.82-13-13-13zM23.258 12.307l-9.486 9.485c-0.238 0.237-0.623 0.237-0.861 0l-0.191-0.191-0.001 0.001-5.219-5.256c-0.238-0.238-0.238-0.624 0-0.862l1.294-1.293c0.238-0.238 0.624-0.238 0.862 0l3.689 3.716 7.756-7.756c0.238-0.238 0.624-0.238 0.862 0l1.294 1.294c0.239 0.237 0.239 0.623 0.001 0.862z"></path> </g></svg>
                </div>
                <p className="title">Login Success!</p>
                <p className="text">Congratulations! You have successfully logged in into your ManyMarket account.</p>
                <button className='button' onClick={closeLoginSuccessModal}>Got It</button>
            </div>
        </div>

        {/* Login Failed Modal */}
        <div className="parent-parent-login-failed" style={{ display: loginFailed ? 'inline-block' : 'none' }}>
            <div className="parent-login-failed">
                <div className="svg-parent">
                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#f00"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M16 8L8 16M12 12L16 16M8 8L10 10M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#f00" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                </div>
                <p className="title">Login Failed!</p>
                <p className="text">User account login has been failed. Please check your credentials carefully.</p>
                <button className='button' onClick={closeLoginFailedModal}>Got It</button>
            </div>
          </div>

        <div className='login-register-section-parent'>
            <div className="section">
                <div className="container">
                    <div className="row full-height justify-content-center">
                        <div className="col-12 text-center align-self-center py-5">
                            <div className="section pb-5 pt-5 pt-sm-2 text-center">
                                <h6 className="mb-0 pb-3" style={{ color: "#f6d36a" }}><span>Log In </span><span>Sign Up</span></h6>
                                <input className="checkbox" type="checkbox" id="reg-log" name="reg-log"/>
                                <label for="reg-log"></label>
                                <div className="card-3d-wrap mx-auto">

                                    <div className="card-3d-wrapper">
                                        <div className="card-front">
                                            <div className="center-wrap">
                                                <div className="section text-center">
                                                    <h4 className="mb-4 pb-3" style={{ color: "#f6d36a" }}>SIGN IN</h4>
                                                    <div className="form-group">
                                                        <input type="email" name="loginemail" onChange={handleChangeLogin} className="form-style" placeholder="Your Email" id="logemail" autocomplete="off" />
                                                        <i class="input-icon uil uil-at">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20"style={{ marginTop: "-5px" }}><path fill="currentColor" d="M14.608 12.172c0 .84.239 1.175.864 1.175c1.393 0 2.28-1.775 2.28-4.727c0-4.512-3.288-6.672-7.393-6.672c-4.223 0-8.064 2.832-8.064 8.184c0 5.112 3.36 7.896 8.52 7.896c1.752 0 2.928-.192 4.727-.792l.386 1.607c-1.776.577-3.674.744-5.137.744c-6.768 0-10.393-3.72-10.393-9.456c0-5.784 4.201-9.72 9.985-9.72c6.024 0 9.215 3.6 9.215 8.016c0 3.744-1.175 6.6-4.871 6.6c-1.681 0-2.784-.672-2.928-2.161c-.432 1.656-1.584 2.161-3.145 2.161c-2.088 0-3.84-1.609-3.84-4.848c0-3.264 1.537-5.28 4.297-5.28c1.464 0 2.376.576 2.782 1.488l.697-1.272h2.016v7.057zm-2.951-3.168c0-1.319-.985-1.872-1.801-1.872c-.888 0-1.871.719-1.871 2.832c0 1.68.744 2.616 1.871 2.616c.792 0 1.801-.504 1.801-1.896z"/></svg>
                                                        </i>
                                                    </div>	
                                                    <div className="form-group mt-2">
                                                        <input type="password" name="loginpass" onChange={handleChangeLogin} className="form-style" placeholder="Your Password" id="logpass" autocomplete="off" />
                                                        <i className="input-icon uil uil-lock-alt">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style={{ marginTop: "-5px" }}><path fill="currentColor" d="M6 22q-.825 0-1.412-.587T4 20V10q0-.825.588-1.412T6 8h1V6q0-2.075 1.463-3.537T12 1q2.075 0 3.538 1.463T17 6v2h1q.825 0 1.413.588T20 10v10q0 .825-.587 1.413T18 22zm6-5q.825 0 1.413-.587T14 15q0-.825-.587-1.412T12 13q-.825 0-1.412.588T10 15q0 .825.588 1.413T12 17M9 8h6V6q0-1.25-.875-2.125T12 3q-1.25 0-2.125.875T9 6z"/></svg>
                                                        </i>
                                                    </div>
                                                    <button onClick={handleLogin} className="btn mt-4">LOGIN</button>
                                                    <p className="mb-0 mt-4 text-center"><a href="#0" class="link">Forgot your password?</a></p>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        
                                        <div className="card-back">
                                            <div className="center-wrap">
                                                <div className="section text-center">
                                                    <h4 className="mb-4 pb-3" style={{ color: "#f6d36a" }}>SIGN UP</h4>
                                                    <div className="form-group">
                                                        <input type="text" name="logname" onChange={handleChange} className="form-style" placeholder="Your Full Name" id="logname" autocomplete="off" />
                                                        <i className="input-icon uil uil-user">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256" style={{ marginTop: "-5px" }}><path fill="currentColor" d="M234.38 210a123.36 123.36 0 0 0-60.78-53.23a76 76 0 1 0-91.2 0A123.36 123.36 0 0 0 21.62 210a12 12 0 1 0 20.77 12c18.12-31.32 50.12-50 85.61-50s67.49 18.69 85.61 50a12 12 0 0 0 20.77-12M76 96a52 52 0 1 1 52 52a52.06 52.06 0 0 1-52-52"/></svg>
                                                            </i>
                                                    </div>	
                                                    <div className="form-group mt-2">
                                                        <input type="email" name="logemail" onChange={handleChange} className="form-style" placeholder="Your Email" id="logemail" autocomplete="off" />
                                                        <i className="input-icon uil uil-at">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20" style={{ marginTop: "-5px" }}><path fill="currentColor" d="M14.608 12.172c0 .84.239 1.175.864 1.175c1.393 0 2.28-1.775 2.28-4.727c0-4.512-3.288-6.672-7.393-6.672c-4.223 0-8.064 2.832-8.064 8.184c0 5.112 3.36 7.896 8.52 7.896c1.752 0 2.928-.192 4.727-.792l.386 1.607c-1.776.577-3.674.744-5.137.744c-6.768 0-10.393-3.72-10.393-9.456c0-5.784 4.201-9.72 9.985-9.72c6.024 0 9.215 3.6 9.215 8.016c0 3.744-1.175 6.6-4.871 6.6c-1.681 0-2.784-.672-2.928-2.161c-.432 1.656-1.584 2.161-3.145 2.161c-2.088 0-3.84-1.609-3.84-4.848c0-3.264 1.537-5.28 4.297-5.28c1.464 0 2.376.576 2.782 1.488l.697-1.272h2.016v7.057zm-2.951-3.168c0-1.319-.985-1.872-1.801-1.872c-.888 0-1.871.719-1.871 2.832c0 1.68.744 2.616 1.871 2.616c.792 0 1.801-.504 1.801-1.896z"/></svg>
                                                        </i>
                                                    </div>
                                                    <div className="form-group mt-2">
                                                        <input type="file" accept="image/*" name="logimage" onChange={handleFile} className="form-style" id="logimage" multiple="false" />
                                                        <i className="input-icon uil uil-at">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style={{ marginTop: "-5px" }}><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><rect width="18" height="18" x="3" y="3" rx="2" ry="2"/><circle cx="9" cy="9" r="2"/><path d="m21 15l-3.086-3.086a2 2 0 0 0-2.828 0L6 21"/></g></svg>
                                                     </i>
                                                    </div>	
                                                    <div className="form-group mt-2">
                                                        <input type="password" name="logpass" onChange={handleChange} className="form-style" placeholder="Your Password" id="logpass" autocomplete="off" />
                                                        <i className="input-icon uil uil-lock-alt">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style={{ marginTop: "-5px" }}><path fill="currentColor" d="M6 22q-.825 0-1.412-.587T4 20V10q0-.825.588-1.412T6 8h1V6q0-2.075 1.463-3.537T12 1q2.075 0 3.538 1.463T17 6v2h1q.825 0 1.413.588T20 10v10q0 .825-.587 1.413T18 22zm6-5q.825 0 1.413-.587T14 15q0-.825-.587-1.412T12 13q-.825 0-1.412.588T10 15q0 .825.588 1.413T12 17M9 8h6V6q0-1.25-.875-2.125T12 3q-1.25 0-2.125.875T9 6z"/></svg>
                                                        </i>
                                                    </div>
                                                    <button onClick={handleClick} className="btn mt-4">REGISTER</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        </>
    )
}

export default LoginRegister