import React, { useState } from 'react';
import './ProjectDetailsDesc.css';

const ProjectDetailsDesc = ({ title, description, certificatePhotos, galleryPhotos, docs }) => {

    const [lightboxImage, setLightboxImage] = useState(null);
    const openLightbox = (image) => {
        setLightboxImage(image);
    };

    const closeLightbox = () => {
        setLightboxImage(null);
    };
    

    // Convert certificatePhotos to an array if it is a string
    let photosArray = [];
    try {
        photosArray = JSON.parse(certificatePhotos);
        if (!Array.isArray(photosArray)) {
            photosArray = [];
        }
    } catch (error) {
        console.error("Failed to parse certificate photos!", error);
    }

    let galleryArray = [];
    try {
        galleryArray = JSON.parse(galleryPhotos);
        if (!Array.isArray(galleryArray)) {
            galleryArray = [];
        }
    } catch (error) {
        console.error("Failed to parse gallery photos!", error);
    }

    let docsArray = [];
    try {
        docsArray = JSON.parse(docs);
        if (!Array.isArray(docsArray)) {
            docsArray = [];
        }
    } catch (error) {
        console.error("Failed to parse docs!", error);
    }

    return (
        <>
            <div className='projectDetail-market-parent-wrapper'>
                <div class="projectDetail-market-wrapper">
                    <div class="projectDetail-market-container">
                        <div class="projectDetail-market-card projectDetail-market-tabs">

                            <input id="projectDetail-tab-1" type="radio" class="projectDetail-tab projectDetail-tab-selector" name="tab" />
                            <label for="projectDetail-tab-1" class="projectDetail-tab projectDetail-tab-primary">Description</label>

                            <input id="projectDetail-tab-2" type="radio" class="projectDetail-tab projectDetail-tab-selector" name="tab" />
                            <label for="projectDetail-tab-2" class="projectDetail-tab tab-success">Certificates</label>

                            <input id="projectDetail-tab-3" type="radio" class="projectDetail-tab projectDetail-tab-selector" name="tab" />
                            <label for="projectDetail-tab-3" class="projectDetail-tab projectDetail-tab-default">Gallery</label>

                            <input id="projectDetail-tab-4" type="radio" class="projectDetail-tab projectDetail-tab-selector" name="tab" />
                            <label for="projectDetail-tab-4" class="projectDetail-tab projectDetail-tab-docs">Docs</label>

                            <div class="projectDetail-tabsShadow"></div>
                            <div class="projectDetail-glider"></div>

                            <section class="projectDetail-content" style={{ paddingBottom: "50px" }}>
                                {/* <div class="projectDetail-item" id="projectDetail-content-1"> */}
                                    <div className='projectDetail-title-text-parent' id="projectDetail-content-1">
                                        <h2>{title}</h2>
                                        <p dangerouslySetInnerHTML={{ __html: description }}></p>
                                    {/* </div> */}
                                    
                                </div>
                                <div class="projectDetail-item" id="projectDetail-content-2">
                                    <div className='projectDetail-gallery'>
                                        {photosArray.map((img, index) => (
                                            <img 
                                                key={index} 
                                                src={`http://localhost:8081/${img.replace(/^public\\?|\\?$/g, "")}`} 
                                                alt={`Certificate ${index + 1}`} 
                                                onClick={() => openLightbox(`http://localhost:8081/${img.replace(/^public\\?|\\?$/g, "")}`)} 
                                            />
                                        ))}
                                    </div>
                                </div>

                                <div class="projectDetail-item" id="projectDetail-content-3">
                                    <div className='projectDetail-gallery'>
                                        {galleryArray.map((img, index) => (
                                            <img 
                                                key={index} 
                                                src={`http://localhost:8081/${img.replace(/^public\\?|\\?$/g, "")}`} 
                                                alt={`Certificate ${index + 1}`} 
                                                onClick={() => openLightbox(`http://localhost:8081/${img.replace(/^public\\?|\\?$/g, "")}`)} 
                                            />
                                        ))}
                                    </div>
                                </div>

                                <div class="projectDetail-item" id="projectDetail-content-4">
                                    <div className='projectDetail-gallery'>
                                    {docsArray.map((doc, index) => {
                                        const docName = doc.split('\\').pop(); // Extract the document name
                                        return (
                                            <a 
                                                key={index} 
                                                href={`http://localhost:8081/${doc.replace(/^public\\?|\\?$/g, "")}`} 
                                                download={docName} // Add the download attribute
                                                className='doc-link'
                                            >
                                                {docName}
                                            </a>
                                        );
                                    })}
                                    </div>
                                </div>
                            </section>
                    
                        </div>
                    </div>
                </div>
            </div>

            {lightboxImage && (
                <div className="lightbox" onClick={closeLightbox}>
                    <div className="lightbox-content">
                        <img src={lightboxImage} alt="Enlarged view" />
                    </div>
                </div>
            )}
        </>
    )
}

export default ProjectDetailsDesc